import { Container, Row, Col, Button } from "reactstrap";
import { useContext } from "react";
import { Link } from "react-router-dom";
import AdminContext from "../../AdminContext";
// core components
const Reports = () => {
  const adminContext = useContext(AdminContext);

  if (adminContext.isTrial) {
    adminContext.history.push("/admin/company/details");
  }
  return (
    <>
      <Container className="mt-5" fluid>
        <Row>
          <Col>
            <h1 className="days-one">No Companies Found</h1>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col>No Company exists, would you like to create a new one?</Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {" "}
            <h3>
              <a
                className="text-underline"
                href="https://www.lightningpayroll.com.au/faq?selected=292"
                target="_blank"
              >
                Learn more about getting started here.
              </a>
            </h3>
          </Col>
        </Row>
        <Row className="mx-2">
          <Col>
            <Button
              onClick={() => {
                adminContext.setCompany({});
                adminContext.history.push("/admin/company/details");
              }}
              className="btn-icon-clipboard bg-success border-radius-45"
            >
              <div className="text-center">
                <i className="ni ni-building  mr-2 text-white" />
              </div>
              <div className="text-wrap text-lg text-white">
                Yes, create a new company.
              </div>
            </Button>
          </Col>
          <Col>
            <Link
              to={"/admin/restore_backup"}
              className="btn-icon-clipboard bg-info border-radius-45"
            >
              <div className="text-center">
                <i className="fa-regular fa-folder-arrow-down mr-2 text-white" />
              </div>
              <div className="text-wrap text-lg text-white">
                No, I have a <strong>&nbsp;local&nbsp;</strong>
                backup file I'd like to restore.
              </div>
            </Link>
          </Col>
          <Col>
            <Link
              to={"/admin/online_backups"}
              className="btn-icon-clipboard bg-info border-radius-45"
            >
              <div className="text-center">
                <i className="fa-regular fa-cloud-arrow-down mr-2 text-white" />
              </div>
              <div className="text-wrap text-lg text-white">
                No, I have an <strong>&nbsp;online&nbsp;</strong> backup I'd
                like to restore.
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
