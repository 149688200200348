import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import DateEdit from "../../components/Widgets/DateEdit";
import ComboBox from "../../components/Widgets/ComboBox";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";
import AdminContext from "../../AdminContext";
import dayjs from "dayjs";

const SuperStreamMailboxView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [superstreamMessageRows, setSuperstreamMessageRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("All");
  const [selectedSuperFundId, setSelectedSuperFundId] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedType, setSelectedType] = useState("contribution");
  const [availableEmployees, setAvailableEmployees] = useState({ All: "All" });
  const [availableStatuses, setAvailableStatuses] = useState({ All: "All" });
  const [availableTypes, setAvailableTypes] = useState({
    contribution: "Contribution",
  });
  const [availableSuperFunds, setAvailableSuperFunds] = useState({
    All: "All",
  });
  const [initialising, setInitialising] = useState(false);
  const [openDirectEntry, setOpenDirectEntry] = useState(false);

  const fetchSuperStreamMailboxData = (filters = {}) => {
    setIsLoading(true);

    let queryParams = new URLSearchParams();
    queryParams.append("company_id", adminContext.company.id);
    if (filters.startDate) queryParams.append("start_date", filters.startDate);
    if (filters.endDate) queryParams.append("end_date", filters.endDate);
    if (filters.employeeId)
      queryParams.append("employee_id", filters.employeeId);
    if (filters.superFundId)
      queryParams.append("super_fund_id", filters.superFundId);
    if (filters.status) queryParams.append("status", filters.status);
    if (filters.type) queryParams.append("type", filters.type);

    adminContext.getRequest(
      `${
        adminContext.constants.BASE_URL
      }/super-fund-deposits/superstream/mailbox/init?${queryParams.toString()}`,
      (response) => {
        setSuperstreamMessageRows(response.data.superstream_message_rows);
        if (!filters.startDate) setStartDate(response.data.start_date);
        if (!filters.endDate) setEndDate(response.data.end_date);
        setAvailableEmployees(response.data.available_employees);
        setAvailableSuperFunds(response.data.available_super_funds);
        setAvailableStatuses(response.data.available_statuses);
        setAvailableTypes(response.data.available_types);
        setIsLoading(false);
        setInitialising(false);
      }
    );
  };

  useEffect(() => {
    setInitialising(true);
    fetchSuperStreamMailboxData();
  }, []);

  useEffect(() => {
    if (openDirectEntry) {
      adminContext.history.push("/admin/superstream/direct_entry");
      setOpenDirectEntry(false);
    }
  }, [adminContext.superStreamMessages]);

  const handleClick = () => {
    setOpenDirectEntry(true);
    adminContext.setSuperStreamMessages([]);
  };

  const filterSuperStreamMailbox = () => {
    fetchSuperStreamMailboxData({
      startDate: startDate,
      endDate: endDate,
      employeeId: selectedEmployeeId,
      status: selectedStatus,
      type: selectedType,
      superFundId: selectedSuperFundId,
    });
  };

  useEffect(() => {
    if (initialising) return;
    if (
      startDate &&
      endDate &&
      selectedEmployeeId &&
      selectedStatus &&
      selectedType &&
      selectedSuperFundId
    ) {
      filterSuperStreamMailbox();
    }
  }, [
    startDate,
    endDate,
    selectedEmployeeId,
    selectedStatus,
    selectedType,
    selectedSuperFundId,
  ]);

  const handleDateChange = (formattedDate, mode) => {
    if (mode == "start") {
      setStartDate(formattedDate);
    } else {
      setEndDate(formattedDate);
    }

    // Depending on the mode update the other date if too low/high
    if (mode == "start") {
      if (dayjs(formattedDate).isAfter(dayjs(endDate))) {
        setEndDate(formattedDate);
      }
    } else {
      if (dayjs(formattedDate).isBefore(dayjs(startDate))) {
        setStartDate(formattedDate);
      }
    }
  };

  const checkForResponses = () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/super-fund-deposits/superstream/mailbox/check-responses/${adminContext.company.id}`,
      (response) => {
        setIsLoading(false);
        // Check for an error
        if (response.data.errors) {
          adminContext.setAlertColor("error");
          adminContext.setAlertMessage(response.data.error);
        } else {
          adminContext.setAlertColor("success");
          const responsesReceived = response.data.responses_received;
          let message =
            "There were " + responsesReceived + " new responses received.";
          adminContext.setAlertMessage(message);

          setInitialising(true);
          fetchSuperStreamMailboxData();
        }
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <BackButton />
              <Row>
                <Col>
                  <h1 className="days-one">SuperStream Mailbox</h1>
                  <p>
                    The SuperStream Mailbox is used for receiving and reviewing
                    of all SuperStream messages, including Contributions and
                    member registrations. Note: Messages are overdue when they
                    pass a certain number of days without a response.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <DateEdit
                    stretch={true}
                    label="Start Date"
                    value={startDate}
                    onChange={(dateTime) => handleDateChange(dateTime, "start")}
                  />
                </Col>
                <Col md="6">
                  <DateEdit
                    label="End Date"
                    stretch={true}
                    value={endDate}
                    onChange={(dateTime) => handleDateChange(dateTime, "end")}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Status"
                    stretch={true}
                    name="status"
                    comboData={availableStatuses}
                    selectedComboItem={selectedStatus}
                    setSelectedComboItem={(status) => {
                      // Status keys are UPPER_CASE but the text is Title Case, so we need to lookup the key
                      // using the value
                      const key = adminContext.getKeyByValue(
                        availableStatuses,
                        status
                      );
                      setSelectedStatus(key);
                    }}
                  />
                </Col>
                <Col md="6">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Fund"
                    stretch={true}
                    name="super_fund_id"
                    comboData={availableSuperFunds}
                    selectedComboItem={selectedSuperFundId}
                    setSelectedComboItem={(data) => {
                      setSelectedSuperFundId(
                        adminContext.getKeyByValue(availableSuperFunds, data)
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Employee"
                    stretch={true}
                    name="employee_id"
                    comboData={availableEmployees}
                    selectedComboItem={selectedEmployeeId}
                    setSelectedComboItem={(data) => {
                      setSelectedEmployeeId(
                        adminContext.getKeyByValue(availableEmployees, data)
                      );
                    }}
                  />
                </Col>
                <Col md="6">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Type"
                    stretch={true}
                    name="type"
                    comboData={availableTypes}
                    selectedComboItem={selectedType}
                    setSelectedComboItem={(type) => {
                      // Status keys are UPPER_CASE but the text is Title Case, so we need to lookup the key
                      // using the value
                      const key = adminContext.getKeyByValue(
                        availableTypes,
                        type
                      );
                      setSelectedType(key);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  // Ensure the buttons are spread evenly and spaced out
                  className="d-flex justify-content-left"
                >
                  <Button
                    className="semi-active-button width-20"
                    onClick={handleClick}
                  >
                    <i className="fa-regular fa-university fa-lg"></i>
                    <br />
                    Direct Entry
                  </Button>
                  {/* <Button
                    className="semi-active-button width-20"
                    onClick={() => alert("BPay Report")}
                  >
                    BPay Report
                  </Button> */}
                  <Button
                    className="semi-active-button width-20"
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/reports/superstream_contributions"
                      )
                    }
                  >
                    <i className="fa-regular fa-line-chart fa-lg"></i>
                    <br />
                    Contributions Report
                  </Button>
                  <Button
                    className="semi-active-button width-20"
                    onClick={checkForResponses}
                  >
                    <i className="fa-regular fa-rotate fa-lg"></i>
                    <br />
                    Check Responses
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mr-3 ml-1 my-3">
                  <TableWidget
                    total={true}
                    orderBy="sent"
                    rows={superstreamMessageRows}
                    columns={[
                      {
                        label: "",
                        accessor: "view",
                        widget: "Link",
                        args: {
                          label: "View",
                          href: "/admin/superstream/superstream_mailbox_message",
                          color: "secondary",
                          className: "btn btn-sm btn-secondary",
                        },
                      },
                      {
                        label: "Super Provider",
                        accessor: "provider_name",
                        widget: "Text",
                      },
                      {
                        label: "Message Type",
                        accessor: "message_type",
                        widget: "Text",
                      },
                      {
                        label: "Status",
                        accessor: "friendly_status",
                        widget: "Text",
                      },
                      {
                        label: "Sent",
                        accessor: "sent",
                        widget: "DateTimeLabel",
                      },
                      {
                        label: "Total",
                        accessor: "total",
                        widget: "MoneyLabel",
                      },
                      {
                        label: "Response Count",
                        accessor: "response_count",
                        widget: "Text",
                      },
                    ]}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SuperStreamMailboxView;
