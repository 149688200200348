import { Container, Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

import ComboBox from "../../components/Widgets/ComboBox";
import SingleSelectListWidget from "../../components/Widgets/SingleSelectListWidget";
import EmployeeListWidget from "../../components/Widgets/EmployeeListWidget";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import EditForm from "../../components/Widgets/EditForm";

const PayRateGroups = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [saving, setSaving] = useState(false);
  const [currentAwardId, setCurrentAwardId] = useState("null");
  const [currentPayRateGroupId, setCurrentPayRateGroupId] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [newPayRateGroup, setNewPayRateGroup] = useState({});
  const [selectedPayRate, setSelectedPayRate] = useState(false);
  const [creatingPRG, setCreatingPRG] = useState(false);
  const [comboData, setComboData] = useState({
    award: {},
  });

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });

  const getCurrentPayRateGroup = () => {
    let cprg = adminContext.company.pay_rate_groups.find(
      (payRateGroup) =>
        Number(payRateGroup.id) === Number(currentPayRateGroupId)
    );
    return cprg;
  };

  const deletePayRateGroup = () => {
    // Remove the current pay rate group from the company
    let payRateGroups = adminContext.company.pay_rate_groups.filter(
      (payRateGroup) =>
        Number(payRateGroup.id) !== Number(currentPayRateGroupId)
    );
    setSaving(true);
    adminContext.setCompany({
      ...adminContext.company,
      pay_rate_groups: payRateGroups,
    });
  };

  useEffect(() => {
    // Undo any editing
    setIsEditing(false);
    setNewPayRateGroup(false);
    // Update the employee rows if the selected pay rate group changes
    let employeeRows = [];
    if (currentPayRateGroupId) {
      let cprg = getCurrentPayRateGroup();
      if (cprg && cprg.employees.length > 0) {
        let employeesWithThisGroup = adminContext.company.employees.filter(
          (employee) => {
            // employee ids are in the cprg.employees array
            let empIds = cprg.employees.map((employee) => employee.id);
            return empIds.includes(employee.id);
          }
        );

        employeeRows = employeesWithThisGroup.map((employee) => {
          return {
            id: employee.id,
            format_name: employee.format_name,
          };
        });
      }
    }

    setSelectedRows({
      ...selectedRows,
      employees: employeeRows,
    });
  }, [currentPayRateGroupId]);

  const getFirstGroupId = (payRateGroups) => {
    if (payRateGroups.length === 0) {
      return null;
    }
    let group = payRateGroups.sort((a, b) => {
      if (a.format_name < b.format_name) {
        return -1;
      }
      if (a.format_name > b.format_name) {
        return 1;
      }
      return 0;
    })[0];

    return group.id;
  };

  const initFromResponse = (response) => {
    // Reset the UI
    setCreatingPRG(false);
    setNewPayRateGroup(false);
    setIsEditing(false);
    setSelectedPayRate(false);
    setSaving(false);
    let awards = adminContext.company.awards;
    if (response.data.awards !== undefined) {
      adminContext.setCompany({
        ...adminContext.company,
        awards: response.data.awards,
        pay_rate_groups: response.data.pay_rate_groups,
      });
      awards = response.data.awards;
    } else {
      adminContext.setCompany({
        ...adminContext.company,
        pay_rate_groups: response.data.pay_rate_groups,
      });
    }

    const awardComboData = { null: "No Award" };
    awards.forEach((award) => {
      awardComboData[Number(award.id)] = award.name;
    });
    setComboData((prevComboData) => ({
      ...prevComboData,
      award: awardComboData,
    }));

    // Get the company_pay_rate_group_id from the server, which is either the id of a newly added cprg or the last selected.
    // If the cprg is no longer in the list, then select the first one in the list, depending on the currently selected award.

    // Get all the groups for the current award
    let awardGroups = response.data.pay_rate_groups.filter(
      (payRateGroup) =>
        Number(payRateGroup.award_id) === Number(currentAwardId) ||
        String(payRateGroup.award_id) === String(currentAwardId)
    );

    // Get the IDs of the groups for the current award
    let awardGroupIds = awardGroups.map((payRateGroup) => payRateGroup.id);

    // If the cprg_id is in the list of groups for the current award, then select it. Otherwise, select the first group in the list.
    if (awardGroupIds.includes(response.data.company_pay_rate_group_id)) {
      setCurrentPayRateGroupId(response.data.company_pay_rate_group_id);
    } else {
      if (awardGroupIds.length > 0) {
        let firstId = getFirstGroupId(awardGroups);
        setCurrentPayRateGroupId(firstId);
      } else {
        setCurrentPayRateGroupId(false);
      }
    }

    setIsLoading(false);
  };

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/pay-rate-groups`,
      (response) => {
        if (response.data.pay_rate_groups) {
          adminContext.setCompany({
            ...adminContext.company,
            pay_rate_groups: response.data.pay_rate_groups,
          });
          setRows((prevRows) => ({
            ...prevRows,
            pay_rate_groups: response.data.pay_rate_groups,
          }));

          initFromResponse(response);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, [currentAwardId]);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=pay_rate_groups${
          creatingPRG === false
            ? "&company_pay_rate_group_id=" + currentPayRateGroupId
            : ""
        }`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
        initFromResponse(response);
        // Check for 200 status
        if (response.status === 200) {
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage("Changes saved successfully");
        }
      }
    );
  };
  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.pay_rate_groups]);

  const getCurrentPayRates = () => {
    if (isNaN(currentPayRateGroupId)) {
      return [];
    }
    let payRateGroup = adminContext.company.pay_rate_groups.find(
      (payRateGroup) =>
        Number(payRateGroup.id) === Number(currentPayRateGroupId)
    );

    if (payRateGroup) {
      return payRateGroup.pay_rates;
    } else {
      return [];
    }
  };

  const getPayRateGroups = (awardId = null) => {
    let groupAwardId = awardId;
    if (awardId === null) {
      groupAwardId = currentAwardId;
    }

    // We can't just return all pay_rate_groups because we need to filter by award
    let currentAward = adminContext.company.awards.find(
      (award) => Number(award.id) === Number(groupAwardId)
    );

    if (currentAward) {
      console.log("currentAward is ");
      console.log(currentAward);
      let currentAwardPRGIds = currentAward.companypayrategroups.map(
        (payRateGroup) => payRateGroup.id
      );
      return adminContext.company.pay_rate_groups.filter((payRateGroup) =>
        currentAwardPRGIds.includes(payRateGroup.id)
      );
    } else {
      let nullAwardIds = adminContext.company.pay_rate_groups
        .filter((payRateGroup) => String(payRateGroup.award_id) === "null")
        .map((payRateGroup) => payRateGroup.id);
      return adminContext.company.pay_rate_groups.filter((payRateGroup) =>
        nullAwardIds.includes(payRateGroup.id)
      );
    }
  };

  const changeAward = (data) => {
    let awardId = adminContext.getKeyByValue(comboData["award"], data);
    setCurrentAwardId(awardId);

    // Now that the selected award has changed we need to update the selected pay rate group
    let prgs = getPayRateGroups(awardId);

    if (prgs.length > 0) {
      setCurrentPayRateGroupId(getFirstGroupId(prgs));
    } else {
      setCurrentPayRateGroupId(null);
    }
  };

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">
                      Pay Rate Groups / Classifications
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-4 my-3">
                    <Row>
                      <Col>
                        <div key={adminContext.company.id}>
                          <label name="classification">
                            <p>
                              Award classification levels and company wide pay
                              rates can be defined below. These can be used to
                              assign common classifications or pay rates to
                              groups of employees.
                            </p>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-3">
                    {!saving ? (
                      <>
                        <Row>
                          {selectedPayRate == false ? (
                            <Col lg={isEditing === false ? "3" : "12"}>
                              {!isEditing ? (
                                <>
                                  <Row>
                                    <Col className="mx-1">
                                      <ComboBox
                                        className="mx-3"
                                        label="Award"
                                        stretch={true}
                                        disabled={
                                          !adminContext.editAccess("company")
                                        }
                                        name="award"
                                        comboData={comboData["award"]}
                                        selectedComboItem={currentAwardId}
                                        setSelectedComboItem={(data) => {
                                          changeAward(data);
                                        }}
                                      />
                                      <p>
                                        Awards are managed in the{" "}
                                        <a
                                          className="text-underline"
                                          href="/admin/company/awards"
                                        >
                                          <strong>Awards</strong>
                                        </a>{" "}
                                        screen.
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <h3 className="days-one">
                                        {" "}
                                        Company Pay Rate Groups
                                      </h3>
                                      <p>
                                        Pay rate group names can be used as
                                        Award Classifications, without the need
                                        to add pay rates.
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row className="mx-1">
                                    <Col className="mx-1 px-1">
                                      <Button
                                        className="width-100 text-center mx-auto my-2"
                                        onClick={() => {
                                          setCreatingPRG(true);
                                          setNewPayRateGroup({
                                            id: null,
                                            employees: [],
                                            pay_rates: [],
                                            header:
                                              "Create a new pay rate group. The name must be unique for this company",
                                            name: "",
                                            award_id: currentAwardId,
                                          });

                                          setIsEditing(true);
                                        }}
                                      >
                                        <i className="fa-regular fa-plus fa-xl text-success mx-0"></i>
                                        <br />
                                        New
                                      </Button>
                                    </Col>
                                    <Col className="mx-1 px-1">
                                      <Button
                                        className="width-100 text-center mx-auto my-2"
                                        disabled={
                                          isNaN(currentPayRateGroupId) ||
                                          !currentPayRateGroupId
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setDeleteConfirmationOpen(true);
                                        }}
                                      >
                                        {" "}
                                        <i className="fa-regular fa-times fa-xl text-danger mx-0"></i>
                                        <br />
                                        Delete
                                      </Button>
                                    </Col>
                                    <Col className="mx-1 px-1">
                                      <Button
                                        disabled={
                                          isNaN(currentPayRateGroupId) ||
                                          !currentPayRateGroupId
                                            ? true
                                            : false
                                        }
                                        className="width-100 text-center mx-auto my-2"
                                        onClick={() => {
                                          let prg =
                                            adminContext.company.pay_rate_groups.find(
                                              (payRateGroup) =>
                                                Number(payRateGroup.id) ===
                                                Number(currentPayRateGroupId)
                                            );
                                          if (prg.award_id === null) {
                                            prg.award_id = "null";
                                          }
                                          setNewPayRateGroup(prg);
                                          setIsEditing(true);
                                        }}
                                      >
                                        {" "}
                                        <i className="fa-regular fa-edit fa-xl mx-0"></i>
                                        <br />
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <SingleSelectListWidget
                                        icon="fa-regular fa-money-check-dollar"
                                        name="pay_rate_groups"
                                        rows={getPayRateGroups()}
                                        selectedItem={currentPayRateGroupId}
                                        setSelectedItem={(e) => {
                                          setCurrentPayRateGroupId(
                                            e.currentTarget.id
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <EditForm
                                  saveChanges={() => {
                                    setSaving(true);
                                    setIsEditing(false);
                                    if (newPayRateGroup.id === null) {
                                      adminContext.setCompany({
                                        ...adminContext.company,
                                        pay_rate_groups: [
                                          ...adminContext.company
                                            .pay_rate_groups,
                                          newPayRateGroup,
                                        ],
                                      });
                                    } else {
                                      adminContext.setCompany({
                                        ...adminContext.company,
                                        pay_rate_groups:
                                          adminContext.company.pay_rate_groups.map(
                                            (payRateGroup) => {
                                              if (
                                                Number(payRateGroup.id) ===
                                                Number(newPayRateGroup.id)
                                              ) {
                                                return newPayRateGroup;
                                              } else {
                                                return payRateGroup;
                                              }
                                            }
                                          ),
                                      });
                                    }
                                  }}
                                  siblings={
                                    adminContext.company.pay_rate_groups
                                  }
                                  formSpec={{
                                    title: "Company Pay Rate Group",
                                    fields: [
                                      {
                                        label: "",
                                        accessor: "header",
                                        widget: "Label",
                                      },
                                      {
                                        label: "Name",
                                        accessor: "name",
                                        unique: true,
                                        required: true,
                                      },
                                      {
                                        label: "Award (Optional)",
                                        accessor: "award_id",
                                        widget: "ComboBox",
                                        args: {
                                          comboDataCallback: () => {
                                            return comboData["award"];
                                          },
                                        },
                                      },
                                    ],
                                  }}
                                  cancelCallback={() => {
                                    setIsEditing(false);
                                    setNewPayRateGroup(false);
                                    setCreatingPRG(false);
                                  }}
                                  setModelObj={setNewPayRateGroup}
                                  modelObj={newPayRateGroup}
                                />
                              )}
                            </Col>
                          ) : null}
                          {isEditing === false ? (
                            <Col>
                              {getCurrentPayRateGroup() ? (
                                <>
                                  {" "}
                                  <Row>
                                    <Col>
                                      {selectedPayRate == false ? (
                                        <p>
                                          Manage pay rates within this group
                                        </p>
                                      ) : null}
                                      <TableWidget
                                        rows={getCurrentPayRates()}
                                        orderBy="description"
                                        selected={selectedPayRate}
                                        setSelected={setSelectedPayRate}
                                        formSpec={{
                                          title: "Company Pay Rate",
                                          fields: [
                                            {
                                              label: "Description",
                                              accessor: "description",
                                              widget: "Text",
                                              validate: {
                                                required: true,
                                              },
                                            },
                                            {
                                              label: "Rate Type",
                                              accessor: "rate_type",
                                              widget: "ComboBox",
                                              required: true,
                                              args: {
                                                comboDataCallback: () => {
                                                  return {
                                                    SPECIAL: "Special",
                                                    NORMAL: "Normal",
                                                  };
                                                },
                                              },
                                            },
                                            {
                                              label: "Rate",
                                              accessor: "rate",
                                              widget: "MoneyEdit",
                                              args: {
                                                decimalPrecision: 5,
                                                visibleCallback: (payRate) => {
                                                  return (
                                                    payRate.rate_type ===
                                                    "SPECIAL"
                                                  );
                                                },
                                              },
                                            },
                                            {
                                              label: "Multiplier",
                                              accessor: "multiplier",
                                              widget: "MultiplierEdit",
                                              args: {
                                                visibleCallback: (payRate) => {
                                                  return (
                                                    payRate.rate_type ===
                                                    "NORMAL"
                                                  );
                                                },
                                              },
                                            },
                                            {
                                              label: "Units",
                                              accessor: "units",
                                              widget: "Text",
                                            },
                                            {
                                              label: "Is Hours?",
                                              accessor: "is_hours",
                                              widget: "CheckBox",
                                            },
                                            {
                                              label: "Is Overtime?",
                                              accessor: "is_overtime",
                                              widget: "CheckBox",
                                            },
                                          ],
                                        }}
                                        appendRowCallback={() => {
                                          return {
                                            id: null,
                                            pay_rate_group_id:
                                              currentPayRateGroupId,
                                            description: "",
                                            rate: { _decimal: 0 },
                                            rate_type: "SPECIAL",
                                            multiplier: 1,
                                            units: "Hours",
                                            is_overtime: false,
                                          };
                                        }}
                                        editRowSaveCallback={(newRate) => {
                                          setSaving(true);
                                          setIsLoading(true);
                                          let currentPayRateGroup =
                                            getCurrentPayRateGroup();
                                          if (newRate.id === null) {
                                            currentPayRateGroup.pay_rates.push(
                                              newRate
                                            );
                                          } else {
                                            currentPayRateGroup.pay_rates =
                                              currentPayRateGroup.pay_rates.map(
                                                (payRate) => {
                                                  if (
                                                    payRate.id === newRate.id
                                                  ) {
                                                    return newRate;
                                                  } else {
                                                    return payRate;
                                                  }
                                                }
                                              );
                                          }

                                          adminContext.setCompany({
                                            ...adminContext.company,
                                            pay_rate_groups:
                                              adminContext.company.pay_rate_groups.map(
                                                (payRateGroup) => {
                                                  if (
                                                    payRateGroup.id ===
                                                    currentPayRateGroup.id
                                                  ) {
                                                    return currentPayRateGroup;
                                                  }
                                                  return payRateGroup;
                                                }
                                              ),
                                          });
                                        }}
                                        deleteRowCallback={(idToDelete) => {
                                          setSaving(true);
                                          let currentPayRateGroup =
                                            getCurrentPayRateGroup();
                                          currentPayRateGroup.pay_rates =
                                            currentPayRateGroup.pay_rates.filter(
                                              (rate) => {
                                                return rate.id !== idToDelete;
                                              }
                                            );
                                          adminContext.setCompany({
                                            ...adminContext.company,
                                            pay_rate_groups:
                                              adminContext.company.pay_rate_groups.map(
                                                (payRateGroup) => {
                                                  if (
                                                    payRateGroup.id ===
                                                    currentPayRateGroup.id
                                                  ) {
                                                    return currentPayRateGroup;
                                                  }
                                                  return payRateGroup;
                                                }
                                              ),
                                          });
                                        }}
                                        deleteConfirmationAttributes={[
                                          "description",
                                          "value",
                                        ]}
                                        columns={[
                                          {
                                            label: "Description",
                                            accessor: "description",
                                          },
                                          {
                                            label: "Rate Type",
                                            accessor: "rate_type",
                                          },
                                          {
                                            label: "Value",
                                            accessor: "value",
                                          },
                                          {
                                            label: "Is Overtime?",
                                            accessor: "is_overtime",
                                            widget: "BooleanLabel",
                                          },
                                        ]}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <hr />
                                  </Row>
                                  {selectedPayRate == false ? (
                                    <Row>
                                      <Col>
                                        <>
                                          <Row>
                                            <Col lg="4">
                                              <br />
                                              <p className="pb-0 mb-0">
                                                Manage employees within this
                                                group
                                              </p>
                                            </Col>
                                            <Col className="text-left">
                                              <SaveChangesMessageRow
                                                label="Save Employee Changes"
                                                errorMessage={errorMessage}
                                                successMessage={successMessage}
                                                saveChanges={() => {
                                                  setSaving(true);
                                                  setIsLoading(true);
                                                  // update the employees from the current pay rate group using the selectedRows
                                                  // from the list widget
                                                  const newPayRateGroups =
                                                    adminContext.company.pay_rate_groups.map(
                                                      (payRateGroup) => {
                                                        if (
                                                          Number(
                                                            payRateGroup.id
                                                          ) ===
                                                          Number(
                                                            currentPayRateGroupId
                                                          )
                                                        ) {
                                                          return {
                                                            ...payRateGroup,
                                                            employees:
                                                              selectedRows.employees,
                                                          };
                                                        } else {
                                                          return payRateGroup;
                                                        }
                                                      }
                                                    );

                                                  adminContext.setCompany({
                                                    ...adminContext.company,
                                                    pay_rate_groups:
                                                      newPayRateGroups,
                                                  });
                                                }}
                                              />
                                            </Col>
                                          </Row>

                                          <EmployeeListWidget
                                            name="employees"
                                            rows={rows}
                                            setRows={setRows}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                          />
                                        </>
                                      </Col>
                                    </Row>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <h2 className="days-one">
                                    No group/classification is currently
                                    selected
                                  </h2>
                                  <p>
                                    Please select a different award option or
                                    create a new company pay rate group to
                                    continue.
                                  </p>
                                </>
                              )}
                            </Col>
                          ) : null}
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <Modal isOpen={deleteConfirmationOpen && currentPayRateGroupId}>
                  <div className="mx-4 my-4 ">
                    <p className="text-center">
                      Are you sure you would like to delete the{" "}
                      <strong>
                        {getCurrentPayRateGroup() !== undefined
                          ? getCurrentPayRateGroup().name
                          : ""}
                      </strong>{" "}
                      pay rate group/classification?
                    </p>
                    <Button
                      color="warning"
                      className="float-right mb-2 mx-2"
                      onClick={() => {
                        deletePayRateGroup();
                        setDeleteConfirmationOpen(false);
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      color="secondary"
                      className="float-right mb-2 mx-2"
                      onClick={() => {
                        setDeleteConfirmationOpen(false);
                      }}
                    >
                      No, cancel
                    </Button>
                  </div>
                </Modal>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default PayRateGroups;
