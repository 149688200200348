import { Container, Row, Col, Button, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ComboBox from "../../../components/Widgets/ComboBox.js";
import AdminContext from "../../../AdminContext";

import { FormControlLabel } from "@mui/material";
import DateEdit from "../../../components/Widgets/DateEdit";
import CheckBox from "@mui/material/Checkbox";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import FileUploadButton from "../../../components/Widgets/FileUploadButton";
import dayjs from "dayjs";

const PersonalTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [imageSuccess, setImageSuccess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [pinVisible, setPinVisible] = useState(false);

  const togglePinVisibility = () => {
    setPinVisible(!pinVisible);
  };
  const [comboData, setComboData] = useState({
    title: {},
    gender: {
      FEMALE: "Female",
      INDETERMINATE: "Indeterminate",
      MALE: "Male",
      UNKNOWN: "Unknown",
    },
  });
  const initCombos = (responseData) => {
    setComboData({
      ...comboData,
      banks: responseData.banks,
      title: responseData.title,
    });
  };

  const getEmployeeId = () => {
    if (adminContext.currentEmployee.id !== undefined) {
      return adminContext.currentEmployee.id;
    }
    adminContext.company.employees.forEach((employee) => {
      if (employee.id !== undefined) {
        return employee.id;
      }
    });
  };
  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/employees/${getEmployeeId()}/init`,
      (response) => {
        initCombos(response.data);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  const handleDateChange = (label, accessor, dateTime) => {
    if (!dayjs(dateTime).isValid()) {
      return;
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      [accessor]: dateTime,
    });
  };

  // Update the employee on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const uploadEmployeeImage = (e) => {
    if (!selectedFile) {
      setImageError("Please select a file to upload");
      setImageSuccess("");

      setTimeout(() => {
        setImageError("");
        setImageSuccess("");
      }, 5000);
      return;
    }
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}/upload-image`,
      formdata,
      (response) => {
        if (response.status === 200 && !response.data.error) {
          setSelectedFile(null);
          setImageError("");
          setImageSuccess("Image uploaded successfully");
          setSelectedFile(null);
        } else if (response.status === 200 && response.data.error) {
          setImageError(response.data.error);
          setImageSuccess("");
        }
        setTimeout(() => {
          setImageError("");
          setImageSuccess("");
        }, 5000);
      }
    );
  };

  const deleteEmployeeImage = (e) => {
    e.preventDefault();
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}/delete-image`,
      (response) => {
        if (response.status === 200 && !response.data.error) {
          setImageError("");
          setImageSuccess("Image deleted successfully");
          setSelectedFile(null);
        } else if (response.status === 200 && response.data.error) {
          setImageError(response.data.error);
          setImageSuccess("");
        }
        setTimeout(() => {
          setImageError("");
          setImageSuccess("");
        }, 5000);
      }
    );
  };
  return (
    <Container className="my-3" fluid>
      {!isLoading ? (
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <AvForm>
                <CardBody>
                  <Row>
                    <Col>
                      <h1 className="days-one">Employee Details</h1>
                    </Col>
                  </Row>
                  <SaveChangesMessageRow
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    saveChanges={saveChanges}
                  />

                  <Row>
                    <Col>
                      <h2>Name</h2>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          label="Title"
                          name="title_id"
                          stretch={true}
                          onChange={handleChange}
                          comboData={comboData["title"]}
                          selectedComboItem={
                            adminContext.currentEmployee.title_id
                          }
                          setSelectedComboItem={(data) =>
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              title_id: adminContext.getKeyByValue(
                                comboData["title"],
                                data
                              ),
                            })
                          }
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          value={adminContext.currentEmployee.first_name}
                          onChange={handleChange}
                          type="text"
                          name="first_name"
                          label="First Name"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          value={adminContext.currentEmployee.middle_name}
                          label="Middle Name"
                          onChange={handleChange}
                          type="text"
                          name="middle_name"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          value={adminContext.currentEmployee.last_name}
                          label="Last Name"
                          onChange={handleChange}
                          type="text"
                          name="last_name"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Personal</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          className="mx-3"
                          stretch={true}
                          label="Gender"
                          name="gender"
                          onChange={handleChange}
                          comboData={comboData["gender"]}
                          selectedComboItem={
                            adminContext.currentEmployee.gender
                          }
                          setSelectedComboItem={(data) =>
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              gender: adminContext.getKeyByValue(
                                comboData["gender"],
                                data
                              ),
                            })
                          }
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Payroll Number"
                          value={adminContext.currentEmployee.number}
                          onChange={handleChange}
                          type="text"
                          name="number"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <DateEdit
                          label="Date Of Birth"
                          value={adminContext.currentEmployee.date_of_birth}
                          onChange={(time) =>
                            handleDateChange(
                              "Date Of Birth",
                              "date_of_birth",
                              time
                            )
                          }
                          type="date"
                          name="date_of_birth"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div
                        key={adminContext.currentEmployee.id}
                        className="d-flex"
                      >
                        <AvField
                          label="Timeclock Pin"
                          value={adminContext.currentEmployee.timeclock_pin}
                          onChange={handleChange}
                          validate={{
                            pattern: {
                              value: "^[0-9]{4}$",
                              errorMessage:
                                "Timeclock pin must be a 4-digit number - (Default is 1234)",
                            },
                          }}
                          type="text" // Use type="text" to avoid password treatment
                          name="timeclock_pin"
                          autoComplete="off" // Prevent browser autocomplete
                          inputMode="numeric" // Show numeric keyboard on mobile devices
                          style={
                            !pinVisible ? { WebkitTextSecurity: "disc" } : null
                          } // Hide characters like a password
                        />
                        <Button
                          onClick={togglePinVisibility}
                          className=" mx-1 pt-3 h-50 my-auto"
                        >
                          <i className="fa-regular fa-eye " />
                        </Button>
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="STP ID"
                          value={adminContext.currentEmployee.stp_id}
                          onChange={handleChange}
                          type="number"
                          name="stp_id"
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          disabled={true}
                          label="Previous STP ID"
                          value={
                            adminContext.currentEmployee.previous_employee_id
                          }
                          name="previous_employee_id"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Contact</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          value={adminContext.currentEmployee.phone_home}
                          onChange={handleChange}
                          type="text"
                          label="Home Phone"
                          name="phone_home"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Mobile Phone"
                          value={adminContext.currentEmployee.phone_mobile}
                          onChange={handleChange}
                          type="text"
                          name="phone_mobile"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Medical/Dr's Phone"
                          value={adminContext.currentEmployee.phone_medical}
                          onChange={handleChange}
                          type="text"
                          name="phone_medical"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Email Address"
                          value={adminContext.currentEmployee.email_address}
                          onChange={handleChange}
                          type="text"
                          name="email_address"
                        />
                      </div>
                    </Col>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .include_email_and_phone_in_stp
                        }
                        control={
                          <CheckBox
                            checked={
                              adminContext.currentEmployee
                                .include_email_and_phone_in_stp
                            }
                            className="mx-2"
                            name="include_email_and_phone_in_stp"
                            onChange={handleChange}
                          />
                        }
                        label={"Include phone and email in STP?"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Address</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          value={adminContext.currentEmployee.address1}
                          label="Address Line 1"
                          onChange={handleChange}
                          type="text"
                          name="address1"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Address Line 2"
                          value={adminContext.currentEmployee.address2}
                          onChange={handleChange}
                          type="text"
                          name="address2"
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="City"
                          value={adminContext.currentEmployee.city}
                          onChange={handleChange}
                          type="text"
                          name="city"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Postcode"
                          type="text"
                          pattern="[0-9]{4}"
                          value={adminContext.currentEmployee.postcode}
                          onChange={handleChange}
                          name="postcode"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          label="State"
                          stretch={true}
                          className="mx-3"
                          name="state"
                          onChange={handleChange}
                          comboData={adminContext.constants.STATES}
                          selectedComboItem={adminContext.currentEmployee.state}
                          setSelectedComboItem={(data) =>
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              state: data,
                            })
                          }
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          label="Country"
                          stretch={true}
                          className="mx-3"
                          name="country"
                          onChange={handleChange}
                          comboData={adminContext.constants.COUNTRY_CODES}
                          selectedComboItem={adminContext.currentEmployee.country.toUpperCase()}
                          setSelectedComboItem={(data) =>
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              country: adminContext.getKeyByValue(
                                adminContext.constants.COUNTRY_CODES,
                                data
                              ),
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr />
                      <h2>Employee Image</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center my-3">
                      {adminContext.currentEmployee.employee_image &&
                      adminContext.currentEmployee.employee_image
                        .image_binary ? (
                        <img
                          key={adminContext.currentEmployee.employee_image}
                          className="employee-image"
                          src={
                            `data:image/${adminContext.currentEmployee.employee_image.name
                              .split(".")
                              .pop()};base64,` +
                            adminContext.currentEmployee.employee_image
                              .image_binary
                          }
                          alt="Employee image"
                        />
                      ) : (
                        "No employee image exists on file"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <FileUploadButton
                          selectedFile={selectedFile}
                          label="Upload Image"
                          handleSubmission={uploadEmployeeImage}
                          setSelectedFile={setSelectedFile}
                          deleteFileCallback={deleteEmployeeImage}
                          deleteVisible={
                            adminContext.currentEmployee.employee_image &&
                            adminContext.currentEmployee.employee_image
                              .image_binary
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  {imageError || imageSuccess ? (
                    <Row>
                      <Col>
                        {imageError && (
                          <Alert className="my-2" color="warning">
                            <strong>{imageError}</strong>
                          </Alert>
                        )}
                        {imageSuccess && (
                          <Alert className="my-2" color="success">
                            <strong>{imageSuccess}</strong>
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </AvForm>
            </Card>
          </Col>
        </Row>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};
export default PersonalTab;
