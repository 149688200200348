import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";

const CustomerDashboard = () => {
  const { customerId } = useParams();
  const [usageData, setUsageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const adminContext = useContext(AdminContext);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [routeSearch, setRouteSearch] = useState("");
  const [trialSource, setTrialSource] = useState("");
  const [dateAdded, setDateAdded] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "timestamp",
    direction: "asc",
  });
  const [threshold, setThreshold] = useState(5);

  useEffect(() => {
    let isMounted = true;

    const fetchUsageData = async () => {
      setLoading(true);
      setError(null);
      const url = `${adminContext.constants.BASE_URL}/admin/init-customer-dashboard/${customerId}`;

      try {
        const response = await adminContext.getRequest(url);
        if (isMounted) {
          if (response.data.error) {
            setError(response.data.error);
          } else {
            const logs = response.data.customer_logs;

            // Filter logs for valid timestamps in the required format
            const validLogs = logs.filter((log) => {
              const timestampRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
              return timestampRegex.test(log.timestamp);
            });

            setUsageData(validLogs);
            setFilteredData(validLogs);
            setTrialSource(response.data.trial_source);
            setDateAdded(response.data.date_added);
            setCustomerEmail(response.data.email);

            if (validLogs.length > 0) {
              // Calculate boundaries for datetime inputs
              const timestamps = validLogs.map((log) =>
                new Date(log.timestamp).getTime()
              );

              const minTimestamp = Math.min(...timestamps);
              const maxTimestamp = Math.max(...timestamps);

              setStartDateTime(
                new Date(minTimestamp).toISOString().slice(0, 16)
              ); // Format for datetime-local
              setEndDateTime(new Date(maxTimestamp).toISOString().slice(0, 16)); // Format for datetime-local
            }
          }
        }
      } catch (err) {
        if (isMounted) {
          setError("Failed to fetch customer usage data.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchUsageData();

    return () => {
      isMounted = false;
    };
  }, [customerId, adminContext]);

  const handleFilter = () => {
    const startTime = new Date(startDateTime).getTime();
    const endTime = new Date(endDateTime).getTime();

    const filtered = usageData.filter((log) => {
      const logTime = new Date(log.timestamp).getTime();
      const isDateInRange =
        isNaN(startTime) ||
        isNaN(endTime) ||
        (logTime >= startTime && logTime <= endTime);
      const isRouteMatch =
        !routeSearch ||
        log.route.toLowerCase().includes(routeSearch.toLowerCase());

      return isDateInRange && isRouteMatch;
    });

    setFilteredData(filtered);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  const chartData = filteredData
    .reduce((acc, log) => {
      const existingRoute = acc.find((entry) => entry.route === log.route);
      if (existingRoute) {
        existingRoute.count += 1;
      } else {
        acc.push({ route: log.route, count: 1 });
      }
      return acc;
    }, [])
    .filter((entry) => entry.count >= threshold) // Apply threshold
    .sort((a, b) => b.count - a.count); // Sort in descending order

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <div>
              <BackButton />
              <h1 className="days-one m-2">Customer Usage Dashboard</h1>
              <div className="mx-3">
                <p>
                  <strong>Visualizing usage for Customer ID</strong>:{" "}
                  {customerId}
                </p>
                <p>
                  <strong>Trial source?</strong>{" "}
                  {trialSource
                    ? adminContext.toTitleCase(trialSource)
                    : "No trial data found for this customer"}
                </p>
                <p>
                  <strong>Trial start date</strong>{" "}
                  {dateAdded
                    ? adminContext.toTitleCase(dateAdded)
                    : ""}
                </p>
                <p>
                  <strong>Email: </strong> {customerEmail}
                </p>
              </div>
              <Form inline className="mb-4">
                <FormGroup>
                  <Label for="startDateTime" className="mr-2">
                    Start DateTime
                  </Label>
                  <Input
                    type="datetime-local"
                    id="startDateTime"
                    value={startDateTime}
                    onChange={(e) => setStartDateTime(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="ml-3">
                  <Label for="endDateTime" className="mr-2">
                    End DateTime
                  </Label>
                  <Input
                    type="datetime-local"
                    id="endDateTime"
                    value={endDateTime}
                    onChange={(e) => setEndDateTime(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="ml-3">
                  <Label for="routeSearch" className="mr-2">
                    Search Route
                  </Label>
                  <Input
                    type="text"
                    id="routeSearch"
                    value={routeSearch}
                    placeholder="Enter route"
                    onChange={(e) => setRouteSearch(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="ml-3">
                  <Label for="threshold" className="mr-2">
                    Min Entries
                  </Label>
                  <Input
                    type="number"
                    id="threshold"
                    value={threshold}
                    onChange={(e) => setThreshold(Number(e.target.value))}
                  />
                </FormGroup>
                <Button color="primary" className="ml-3" onClick={handleFilter}>
                  Apply Filter
                </Button>
              </Form>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={chartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="route"
                    tick={{ angle: 90, textAnchor: "start" }}
                    interval={0}
                    height={100}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <hr />
              <h2>Filtered Data Table</h2>
              <Table striped>
                <thead>
                  <tr>
                    <th onClick={() => handleSort("timestamp")}>
                      Timestamp{" "}
                      {sortConfig.key === "timestamp"
                        ? sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                    <th onClick={() => handleSort("route")}>
                      Route{" "}
                      {sortConfig.key === "route"
                        ? sortConfig.direction === "asc"
                          ? "▲"
                          : "▼"
                        : ""}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((log, index) => (
                    <tr key={index}>
                      <td>{log.timestamp}</td>
                      <td>{log.route}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CustomerDashboard;
