import { useEffect, useState, useContext } from "react";
import {
  Container,
  Card,
  CardBody,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import CustomerSummaryTable from "./CustomerSummaryTable";
import TrialSourcesTable from "./TrialSourcesTable";

const getDefaultDates = () => {
  const now = new Date();
  const pastWeek = new Date();
  pastWeek.setDate(now.getDate() - 7);

  const formatDate = (date) => date.toISOString().slice(0, 16);

  return {
    start: formatDate(pastWeek),
    end: formatDate(now),
  };
};

const getStoredDate = (key, defaultValue) => {
  try {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      const date = new Date(storedValue);
      if (!isNaN(date.getTime())) {
        return storedValue;
      }
    }
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error);
  }
  return defaultValue;
};

const AllCustomersDashboard = () => {
  const [allUsageData, setAllUsageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [trialSources, setTrialSources] = useState({});
  const [filteredTrialSources, setFilteredTrialSources] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const adminContext = useContext(AdminContext);

  // Filter states
  const [customerSearch, setCustomerSearch] = useState("");
  const [routeSearch, setRouteSearch] = useState("");

  const { start, end } = getDefaultDates();
  const [startDatetime, setStartDatetime] = useState(
    getStoredDate("startDatetime", start)
  );
  const [endDatetime, setEndDatetime] = useState(
    getStoredDate("endDatetime", end)
  );

  // Checkbox states for filtering trial sources
  const [showConverted, setShowConverted] = useState(() => {
    const storedValue = localStorage.getItem("showConverted");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  const [showPaid, setShowPaid] = useState(() => {
    const storedValue = localStorage.getItem("showPaid");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem("startDatetime", startDatetime);
  }, [startDatetime]);

  useEffect(() => {
    localStorage.setItem("endDatetime", endDatetime);
  }, [endDatetime]);

  useEffect(() => {
    localStorage.setItem("showConverted", JSON.stringify(showConverted));
  }, [showConverted]);

  useEffect(() => {
    localStorage.setItem("showPaid", JSON.stringify(showPaid));
  }, [showPaid]);
  useEffect(() => {
    const fetchAllUsageData = async () => {
      setLoading(true);
      setError(null);
      adminContext.getRequest(
        `${adminContext.constants.BASE_URL}/admin/init-all-customers-dashboard`,
        (response) => {
          setLoading(false);
          if (response.data.error) {
            setError(response.data.error);
          } else {
            setAllUsageData(response.data.customer_logs);
            setFilteredData(response.data.customer_logs); // initially no filter
            setTrialSources(response.data.trial_sources);
            setFilteredTrialSources(response.data.trial_sources); // initially no filter
          }
        }
      );
    };

    fetchAllUsageData();
  }, []);

  useEffect(() => {
    // Update filtered trial sources based on checkbox states
    const filtered = Object.entries(trialSources)
      .filter(([customerId, source]) => {
        const isConverted = source.converted;
        const isPaid = source.paid;

        // Logic to filter based on showConverted and showPaid
        if (showConverted && showPaid) {
          // Include only sources that are both converted and paid
          return isConverted && isPaid;
        } else if (showConverted && !showPaid) {
          // Include only sources that are converted, regardless of paid status
          return isConverted;
        } else if (!showConverted && showPaid) {
          // Include only sources that are paid, regardless of converted status
          return isPaid;
        } else {
          return !isConverted && !isPaid; // Exclude all sources
        }
      })
      .reduce((acc, [id, source]) => {
        acc[id] = source;
        return acc;
      }, {});

    setFilteredTrialSources(filtered);

    // Apply the filter on the main data
    applyFilter(filtered);
  }, [showConverted, showPaid, trialSources]);

  const applyFilter = (filteredTrialSources) => {
    const filtered = allUsageData.filter((log) => {
      const isCustomerIncluded = filteredTrialSources.hasOwnProperty(
        log.customerId
      );

      const matchesCustomer = log.customerId
        .toString()
        .toLowerCase()
        .includes(customerSearch.toLowerCase());

      const matchesDatetime =
        (!startDatetime ||
          new Date(log.timestamp) >= new Date(startDatetime)) &&
        (!endDatetime || new Date(log.timestamp) <= new Date(endDatetime));

      const matchesRoute = log.route
        .toLowerCase()
        .includes(routeSearch.toLowerCase());

      // Include log only if the customer is in filteredTrialSources
      return (
        isCustomerIncluded && matchesCustomer && matchesDatetime && matchesRoute
      );
    });

    setFilteredData(filtered);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents default form submission
    applyFilter(filteredTrialSources); // Applies the filter on form submit
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <div>
              <h1 className="days-one m-2">Web Customers Usage Dashboard</h1>
              <Form
                inline
                className="mb-4"
                onSubmit={handleSubmit} // Attach the submit handler
              >
                {/* Customer ID Filter */}
                <FormGroup>
                  <Label for="customerSearch" className="mr-2">
                    Search Customer ID
                  </Label>
                  <Input
                    type="text"
                    id="customerSearch"
                    value={customerSearch}
                    placeholder="Enter Customer ID"
                    onChange={(e) => setCustomerSearch(e.target.value)}
                  />
                </FormGroup>

                {/* Start Datetime Filter */}
                <FormGroup className="ml-3">
                  <Label for="startDatetime" className="mr-2">
                    Start Datetime
                  </Label>
                  <Input
                    type="datetime-local"
                    id="startDatetime"
                    value={startDatetime}
                    onChange={(e) => setStartDatetime(e.target.value)}
                  />
                </FormGroup>

                {/* End Datetime Filter */}
                <FormGroup className="ml-3">
                  <Label for="endDatetime" className="mr-2">
                    End Datetime
                  </Label>
                  <Input
                    type="datetime-local"
                    id="endDatetime"
                    value={endDatetime}
                    onChange={(e) => setEndDatetime(e.target.value)}
                  />
                </FormGroup>

                {/* Route Filter */}
                <FormGroup className="ml-3">
                  <Label for="routeSearch" className="mr-2">
                    Route Contains
                  </Label>
                  <Input
                    type="text"
                    id="routeSearch"
                    value={routeSearch}
                    placeholder="Enter route fragment"
                    onChange={(e) => setRouteSearch(e.target.value)}
                  />
                </FormGroup>

                {/* Apply Filter Button */}
                <Button color="primary" className="ml-3" type="submit">
                  Apply Filter
                </Button>
              </Form>

              {/* Trial Sources Checkboxes */}
              <FormGroup>
                <Label check className="m-4">
                  <Input
                    type="checkbox"
                    checked={showConverted}
                    onChange={(e) => setShowConverted(e.target.checked)}
                  />{" "}
                  Show Converted
                </Label>
                <Label check className="m-4">
                  <Input
                    type="checkbox"
                    checked={showPaid}
                    onChange={(e) => setShowPaid(e.target.checked)}
                  />{" "}
                  Show Paid
                </Label>
              </FormGroup>

              <hr />

              <TrialSourcesTable
                trialSources={filteredTrialSources}
                filteredData={filteredData}
                allTrialSources={trialSources}
              />

              {/* Pass the final filtered data to the summary table */}
              <CustomerSummaryTable
                data={filteredData}
                allUsageData={allUsageData}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default AllCustomersDashboard;
