import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

const AwardsTabView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/awards`,
      (response) => {
        if (response.data.awards) {
          adminContext.setCompany({
            ...adminContext.company,
            awards: response.data.awards,
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const setCurrentAward = (award) => {
    adminContext.setCompany({
      ...adminContext.company,
      currentAward: award,
    });
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setCurrentAward(false);
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=awards`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.awards]);

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Awards</h1>
                  </Col>
                </Row>
                {!adminContext.company.currentAward ? (
                  <Row>
                    <Col className="mx-4 my-3">
                      <Row>
                        <Col>
                          <div key={adminContext.company.id}>
                            <label name="classification">
                              Classifications can be added in the{" "}
                              <strong>
                                Company
                                {" >> "}Pay Rate Groups
                              </strong>{" "}
                              area. Employees can be added in the{" "}
                              <strong>Employees{" >> "}Pay Settings</strong> or
                              the{" "}
                              <strong>
                                Company
                                {" >> "}Pay Rate Groups
                              </strong>{" "}
                              area. <br />
                              Please note that terminated employees still count
                              towards the award employee counts.
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col className="mx-4 my-3">
                    {!saving ? (
                      <Row>
                        <Col>
                          <TableWidget
                            rows={adminContext.company.awards}
                            setRows={(newRows) => {
                              adminContext.setCompany({
                                ...adminContext.company,
                                awards: newRows,
                              });
                            }}
                            columns={[
                              {
                                label: "Name",
                                accessor: "name",
                                widget: "text",
                              },
                              {
                                label: "Agreement Id",
                                accessor: "agreement_id",
                                widget: "text",
                              },
                              {
                                label: "Version",
                                accessor: "version",
                                widget: "text",
                              },
                              {
                                label: "Operative Date",
                                accessor: "operative_date",
                                widget: "DateLabel",
                              },
                              {
                                label: "Expiry Date",
                                accessor: "expiry_date",
                                widget: "DateLabel",
                              },
                              {
                                label: "Active Employees",
                                accessor: "no_employees_active",
                                widget: "text",
                              },
                              {
                                label: "Inactive Employees",
                                accessor: "no_employees_inactive",
                                widget: "text",
                              },
                              {
                                label: "Classifications",
                                accessor: "no_classifications",
                                widget: "text",
                              },
                            ]}
                            selected={adminContext.company.currentAward}
                            setSelected={setCurrentAward}
                            appendRowCallback={() => {
                              return {
                                id: null,
                                company_id: adminContext.company.id,
                                operative_date: new Date().toISOString(),
                                expiry_date: new Date().toISOString(),
                              };
                            }}
                            editRowSaveCallback={(award) => {
                              setIsLoading(true);
                              setSaving(true);
                              let newAwards = [];
                              if (award.id === null) {
                                newAwards = [
                                  ...adminContext.company.awards,
                                  award,
                                ];
                              } else {
                                newAwards = adminContext.company.awards.map(
                                  (d) => {
                                    if (d.id === award.id) {
                                      return award;
                                    } else {
                                      return d;
                                    }
                                  }
                                );
                              }
                              adminContext.setCompany({
                                ...adminContext.company,
                                awards: newAwards,
                              });
                            }}
                            deleteRowCallback={(idToDelete) => {
                              adminContext.setCompany({
                                ...adminContext.company,
                                awards: adminContext.company.awards.filter(
                                  (award) => award.id !== idToDelete
                                ),
                                currentAward: false,
                              });
                              setSaving(true);
                            }}
                            deleteConfirmationAttributes={[
                              "name",
                              "version",
                              "operative_date",
                              "expiry_date",
                            ]}
                            formSpec={{
                              title: "Awards",
                              fields: [
                                {
                                  label: "Name",
                                  accessor: "name",
                                  widget: "text",
                                  unique: true,
                                  validate: {
                                    required: {
                                      value: true,
                                      errorMessage: "Award name is required",
                                    },
                                    minLength: { value: 1 },
                                    maxLength: { value: 80 },
                                  },
                                },
                                {
                                  label: "Agreement Id",
                                  accessor: "agreement_id",
                                  widget: "text",
                                },
                                {
                                  label: "Version",
                                  accessor: "version",
                                  widget: "text",
                                },
                                {
                                  label: "Operative Date",
                                  accessor: "operative_date",
                                  widget: "DateEdit",
                                },
                                {
                                  label: "Expiry Date",
                                  accessor: "expiry_date",
                                  widget: "DateEdit",
                                  required: false,
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default AwardsTabView;
