import { Button, Input, Row, Col } from "reactstrap";

const FileUploadButton = (props) => {
  const changeHandler = (event) => {
    if (props.setSelectedFile) {
      props.setSelectedFile(event.target.files[0]);
    }
    if (event.target.files[0] && props.autoSubmit) {
      props.handleSubmission(event.target.files[0]);
    }
  };

  return (
    <>
      <Row>
        <Col className="mx-auto" md={props.deleteFileCallback ? "2" : ""}>
          <Input
            className="mt-3"
            type="file"
            name="file"
            onChange={changeHandler}
          />
        </Col>
        <Col>
          {props.selectedFile ? (
            <div className="">
              <p>Filesize: {(props.selectedFile.size / 1e6).toFixed(2)} MB</p>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        {props.selectedFile ? (
          <Col md={props.deleteFileCallback ? "6" : ""}>
            <Button
              className="mt-4 file-uploader-button"
              color="primary"
              type="button"
              onClick={props.handleSubmission}
            >
              <i className="fa fa-upload" /> {props.label}
            </Button>
          </Col>
        ) : null}
        {props.deleteVisible ? (
          <Col>
            {props.deleteFileCallback ? (
              <Button
                className="mt-4 file-uploader-button"
                color="danger"
                type="button"
                onClick={props.deleteFileCallback}
              >
                <i className="fa fa-times" /> Delete
              </Button>
            ) : null}
          </Col>
        ) : null}
      </Row>
    </>
  );
};
export default FileUploadButton;
