import React, { useMemo, useState, useContext } from "react";
import { Table, Button } from "reactstrap";
import AdminContext from "../../AdminContext";
import DateLabel from "../../components/Widgets/DateLabel";

const TrialSourcesTable = ({ trialSources, filteredData, allTrialSources }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "expiry",
    direction: "desc",
  });
  const [showRows, setShowRows] = useState(true); // State to toggle row visibility
  const adminContext = useContext(AdminContext);
  // Compute filtered trial sources based on filteredData
  const filteredTrialSources = useMemo(() => {
    const customerIds = new Set(filteredData.map((log) => log.customerId));
    return Object.entries(trialSources).filter(([customerId]) =>
      customerIds.has(Number(customerId))
    );
  }, [trialSources, filteredData]);

  // Function to sort data based on the column key and direction
  const sortedTrialSources = useMemo(() => {
    if (!sortConfig) return filteredTrialSources;

    const sortedData = [...filteredTrialSources].sort(
      ([idA, dataA], [idB, dataB]) => {
        let valueA = idA;
        let valueB = idB;

        if (sortConfig.key !== "customerId") {
          valueA = dataA[sortConfig.key];
          valueB = dataB[sortConfig.key];
        }

        // Check if the values are number strings (not booleans)
        if (!isNaN(valueA) && !isNaN(valueB)) {
          valueA = Number(valueA);
          valueB = Number(valueB);
        }

        // Handle different types (numbers, strings, booleans)
        if (typeof valueA === "string") valueA = valueA.toLowerCase();
        if (typeof valueB === "string") valueB = valueB.toLowerCase();

        if (valueA < valueB) return sortConfig.direction === "asc" ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
    );

    return sortedData;
  }, [filteredTrialSources, sortConfig]);

  // Handle sorting direction and key on header click
  const handleSort = (key) => {
    setSortConfig((prev) => {
      const direction =
        prev.key === key && prev.direction === "asc" ? "desc" : "asc";
      return { key, direction };
    });
  };

  const trialAgain = (customerId) => {
    console.log("Trial again for customer ID:", customerId);
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/admin/trial-again/${customerId}`,
      {},
      (response) => {
        if (response.data.error) {
          adminContext.setAlertColor("danger");
          adminContext.setAlertMessage(response.data.error);
        } else {
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage(response.data.message);
        }
      }
    );
  };

  return (
    <div>
      <h2 className="mt-4">
        Showing {showRows ? Object.keys(sortedTrialSources).length : 0} {"/"}{" "}
        {Object.keys(allTrialSources).length} customer records
      </h2>
      <Button
        color="primary"
        onClick={() => setShowRows((prev) => !prev)}
        className="mb-3"
      >
        {showRows ? "Hide Rows" : "Show Rows"}
      </Button>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => handleSort("customerId")}>
              Customer ID{" "}
              {sortConfig.key === "customerId" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th>Trial Again?</th>
            <th>Customer Dashboard</th>
            <th onClick={() => handleSort("trial_source")}>
              Trial Source{" "}
              {sortConfig.key === "trial_source" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("date_added")}>
              Trial Start{" "}
              {sortConfig.key === "date_added" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("expiry")}>
              Trial Expiry{" "}
              {sortConfig.key === "expiry" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("email")}>
              Email{" "}
              {sortConfig.key === "email" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("converted")}>
              Converted{" "}
              {sortConfig.key === "converted" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("paid")}>
              Paid{" "}
              {sortConfig.key === "paid" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        {showRows && (
          <tbody>
            {sortedTrialSources.length > 0 ? (
              sortedTrialSources.map(([customerId, data], rowCount) => (
                <tr key={customerId}>
                  <td>{rowCount + 1}</td>

                  <td>{customerId}</td>
                  <td>
                    <Button onClick={() => trialAgain(customerId)}>
                      <i className="fa-regular fa-refresh fa-xl"></i>
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={(cust) =>
                        adminContext.history.push(
                          "/admin/customer-dashboard/" + customerId
                        )
                      }
                    >
                      <i className="fa-regular fa-line-chart fa-xl"></i>
                    </Button>
                  </td>
                  <td>{data.trial_source}</td>
                  <td>
                    <DateLabel value={data.date_added} />
                  </td>
                  <td>
                    <DateLabel value={data.expiry} />
                  </td>
                  <td>{data.email}</td>
                  <td>{data.converted ? "Yes" : "No"}</td>
                  <td>{data.paid ? "Yes" : "No"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No matching trial sources found.
                </td>
              </tr>
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default TrialSourcesTable;
