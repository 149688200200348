import { Container, Row, Col, Button } from "reactstrap";
import { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import ComboBox from "../../components/Widgets/ComboBox";
import AdminContext from "../../AdminContext";
import { Card } from "@material-ui/core";
import DateRangeEdit from "components/Widgets/DateRangeEdit";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListWidget from "components/Widgets/ListWidget";
import Modal from "react-modal";
import FontPicker from "components/Widgets/FontPicker";

import { FaWindowClose } from "react-icons/fa";
import {
  updateEmployeeLists,
  processReport,
  initReport,
} from "./CustomReportsSharedFunctions";
// Set the app element to ensure screen readers handle it properly
Modal.setAppElement("#root");

// ReportBuilder component
function ReportBuilder() {
  const { paramReportType } = useParams();
  const reportTypes = ["pay", "employee"];
  const reportType = reportTypes.includes(paramReportType)
    ? paramReportType
    : "pay";
  const adminContext = useContext(AdminContext);
  const [form, setForm] = useState({
    selected_report_id: 0,
    use_processed_date: true,
    use_processed_date_visible: false,
    show_terminated: true,
    show_only_totals: false,
    show_only_totals_visible: false,
    group_by_department: false,
    group_by_department_visible: false,
    start_date: new Date(),
    end_date: new Date(),
    employees: [],
    columns: [],
    existing_reports: {},
    report_unsaved: true,
    report_name: "Untitled Report",
    fonts: [],
    font_family: "Arial",
    font_size: 7,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
    columns: [],
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
    columns: [],
  });

  const [reactPdfDocument, setReactPdfDocument] = useState(null);
  const customStyle = {
    overlay: { zIndex: 1000 },
  };
  const [pdfB64Data, setPdfB64Data] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);

  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  // Monitor selected_report_id changes to trigger backend call if valid
  useEffect(() => {
    initReport(
      adminContext,
      form,
      setForm,
      reportType,
      setIsLoading,
      rows,
      setRows,
      setSelectedRows,
      false
    );
  }, [form.selected_report_id]);

  useEffect(() => {
    updateEmployeeLists(
      rows,
      setRows,
      adminContext,
      form,
      selectedRows,
      setSelectedRows
    );
  }, [form.show_terminated]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteReport = () => {
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        `/reports/custom/company/${adminContext.company.id}/delete/${form.selected_report_id}`,
      (response) => {
        if (response.data.form) {
          setForm(response.data.form);
          if (response.data.form.columns) {
            setRows({
              employees: response.data.form.employees,
              columns: response.data.form.columns,
            });
          }
          if (response.data.form.selected_columns) {
            setSelectedRows({
              employees: response.data.form.employees,
              columns: response.data.form.selected_columns,
            });
          }
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage("Report deleted successfully");
        } else {
          adminContext.setAlertColor("danger");
          adminContext.setAlertMessage("Report could not be deleted");
        }
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      {!isLoading && form ? (
        <Card className="p-3 m-3">
          <Row className="mx-3">
            <Col>
              <h1 className="days-one">
                {adminContext.toTitleCase(reportType)} Report Builder
              </h1>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col md="12" lg="6">
              <h3>
                Report Name (
                {form.selected_report_id > 0
                  ? "Currently editing: " +
                    form.existing_reports[form.selected_report_id]
                  : "Creating a new report"}
                )
              </h3>
              <input
                type="text"
                value={form.report_name}
                onChange={(e) =>
                  setForm({ ...form, report_name: e.target.value })
                }
                required
                className="form-control"
                placeholder="Report Name"
              />
            </Col>
            <Col lg="3">
              <FontPicker
                fonts={form.fonts}
                fontFamily={form.font_family}
                setFontFamily={(data) => {
                  setForm({ ...form, font_family: data });
                }}
              />
            </Col>
            <Col lg="3">
              <ComboBox
                className="mx-3"
                label="Font Size"
                stretch={true}
                name="font_size"
                selectedComboItem={form.font_size}
                setSelectedComboItem={(data) => {
                  setForm({ ...form, font_size: data });
                }}
                comboData={adminContext.fontSizes()}
              />
            </Col>
          </Row>
          <Row className="m-3">
            <Col lg="6" md="12">
              <Row>
                <Col lg="7" md="12">
                  <br />
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() =>
                      processReport(
                        false,
                        false,
                        false,
                        adminContext,
                        selectedRows,
                        form,
                        setForm,
                        setPdfB64Data,
                        setReactPdfDocument,
                        openModal,
                        reportType,
                        setIsLoading
                      )
                    }
                    className="semi-active-button m-1"
                  >
                    Print/Preview (PDF)
                  </Button>
                  <Button
                    size="lg"
                    color="secondary"
                    onClick={() =>
                      processReport(
                        true,
                        false,
                        false,
                        adminContext,
                        selectedRows,
                        form,
                        setForm,
                        setPdfB64Data,
                        setReactPdfDocument,
                        openModal,
                        reportType,
                        setIsLoading
                      )
                    }
                    className="semi-active-button m-1"
                  >
                    Save Report
                  </Button>
                  <Button
                    size="lg"
                    color="secondary"
                    onClick={() => {
                      processReport(
                        false,
                        true,
                        false,
                        adminContext,
                        selectedRows,
                        form,
                        setForm,
                        setPdfB64Data,
                        setReactPdfDocument,
                        openModal,
                        reportType,
                        setIsLoading
                      );
                    }}
                    className="semi-active-button m-1"
                  >
                    Export CSV
                  </Button>
                  {form.selected_report_id > 0 && (
                    <Button
                      size="lg"
                      color="danger"
                      onClick={deleteReport}
                      className="semi-active-button m-1"
                    >
                      Delete "{form.existing_reports[form.selected_report_id]}"
                      ?
                    </Button>
                  )}
                </Col>
                <Col lg="5" md="12">
                  {" "}
                  <ComboBox
                    label="Load From Existing Reports"
                    name="id"
                    comboData={form.existing_reports}
                    selectedComboItem={form.selected_report_id}
                    setSelectedComboItem={(data) => {
                      setForm({
                        ...form,
                        selected_report_id: adminContext.getKeyByValue(
                          form.existing_reports,
                          data
                        ),
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="12">
              <DateRangeEdit
                startDateProps={{
                  label: "Start Date",
                  value: form.start_date,
                  onChange: (time) => {
                    setForm({ ...form, start_date: time });
                  },
                }}
                endDateProps={{
                  label: "End Date",
                  value: form.end_date,
                  onChange: (time) => {
                    setForm({ ...form, end_date: time });
                  },
                }}
              />
            </Col>
          </Row>

          <Row className="m-3">
            <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
              <FormControlLabel
                control={
                  <CheckBox
                    color="primary"
                    className="mx-2"
                    name="show_terminated"
                    checked={!!form.show_terminated}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        show_terminated: e.target.checked,
                      });
                    }}
                  />
                }
                label={"Show Terminated Employees?"}
              />
            </Col>
            {form.use_processed_date_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="use_processed_date"
                      checked={!!form.use_processed_date}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          use_processed_date: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Use Processed Date?"}
                />
              </Col>
            )}
            {form.show_only_totals_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="show_only_totals"
                      checked={!!form.show_only_totals}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          show_only_totals: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Show Totals Only?"}
                />
              </Col>
            )}

            {form.group_by_department_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="group_by_department"
                      checked={!!form.group_by_department}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          group_by_department: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Group By Department?"}
                />
              </Col>
            )}
          </Row>
          <Row className="m-3">
            <Col lg="6" md="12">
              <h3>Employees</h3>
              <ListWidget
                name="employees"
                rows={rows}
                setRows={setRows}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Col>
            <Col lg="6" md="12">
              <h3>Custom Report Columns</h3>
              <ListWidget
                name="columns"
                rows={rows}
                setRows={setRows}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {pdfB64Data && !adminContext.isNative && (
                <div>
                  <Modal
                    style={customStyle}
                    isOpen={modalIsOpen}
                    contentLabel="Lightning Payroll Report"
                  >
                    <Button
                      color="secondary"
                      onClick={closeModal}
                      className="float-right mb-2"
                    >
                      <FaWindowClose className="mr-3 mb-1" />
                      Close
                    </Button>
                    {reactPdfDocument}
                  </Modal>
                </div>
              )}
            </Col>
            <Col></Col>
          </Row>
        </Card>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}
export default ReportBuilder;
