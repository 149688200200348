import { Typography, Grid, Box } from "@material-ui/core";
import MoneyLabel from "../../../../components/Widgets/MoneyLabel";
import DateLabel from "../../../../components/Widgets/DateLabel";
import AdminContext from "../../../../AdminContext";
import { useContext, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";

function SummaryView({
  payTermination,
  setPayTermination,
  refreshPayTermination,
}) {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPayTermination({
      ...payTermination,
      requires_tax_recalc: false,
    });
  }, []);

  useEffect(() => {
    if (!payTermination.requires_tax_recalc) {
      refreshPayTermination(setLoading);
    }
  }, [payTermination.requires_tax_recalc]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="days-one mx-3, my-3">Termination Summary</h1>
          <Box>
            <Box mt={2}>
              <Row>
                <Col>
                  <Typography variant="h6" className="font-heavy">
                    Termination Options
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Termination Date:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <DateLabel value={payTermination.termination_date} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Reason:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {
                      adminContext.constants.TERMINATION_REASONS[
                        payTermination.reason
                      ]
                    }
                  </Typography>
                </Col>
              </Row>
              {adminContext.constants.CESSATION_TYPES[
                payTermination.stp_termination_type
              ] && (
                <Row>
                  <Col md="4">
                    <Typography variant="body1">
                      <strong>STP Cessation Code:</strong>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography variant="body1">
                      {
                        adminContext.constants.CESSATION_TYPES[
                          payTermination.stp_termination_type
                        ]
                      }
                    </Typography>
                  </Col>
                </Row>
              )}
            </Box>

            {payTermination.reason === "INVALIDITY" ? (
              <Box mt={2}>
                <Typography variant="h6" className="font-heavy">
                  Termination due to ill-health
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Row>
                      <Col>
                        <Typography variant="body1">
                          <strong>Last Retirement Date:</strong>
                        </Typography>
                      </Col>
                      <Col>
                        <Typography variant="body1">
                          <DateLabel
                            value={payTermination.last_retirement_date}
                          />
                        </Typography>
                      </Col>
                      <Col> </Col>
                    </Row>
                  </Grid>
                </Grid>
              </Box>
            ) : null}

            {payTermination.reason === "REDUNDANCY" ? (
              <Box mt={2}>
                <Typography variant="h6" className="font-heavy">
                  Redundancy or Early Retirement Scheme
                </Typography>

                <Row>
                  <Col md="4">
                    <Typography variant="body1">
                      <strong>
                        Redundancy or Early Retirement Scheme payout:
                      </strong>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography variant="body1">
                      <MoneyLabel amount={payTermination.redundancy_amount} />
                    </Typography>
                  </Col>
                </Row>
              </Box>
            ) : null}

            <Box mt={2}>
              <Typography variant="h6" className="font-heavy">
                Non-ETP payment options
              </Typography>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Pay unused holiday/annual leave?:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {payTermination.is_unused_holiday_paid ? "Yes" : "No"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Pay unused leave loading?:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {payTermination.is_unused_leave_loading_paid ? "Yes" : "No"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Pay unused long service leave?:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {payTermination.is_unused_lsl_paid ? "Yes" : "No"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Other Non-ETP payment:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.non_etp_amount} />
                  </Typography>
                </Col>
              </Row>
            </Box>
            <Box mt={2}>
              <Typography variant="h6" className="font-heavy">
                ETP payment options
              </Typography>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Pay wages in lieu of notice?:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {payTermination.is_payment_in_lieu_paid ? "Yes" : "No"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Pay unused sick/personal/carer's leave?:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {payTermination.is_unused_sick_paid ? "Yes" : "No"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Other ETP payment:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.etp_amount} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>ETP tax free payment:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel
                      amount={payTermination.etp_tax_free_component}
                    />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>ETP tax withheld:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.etp_tax_withheld} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>ETP taxable component:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.etp_taxable_component} />
                  </Typography>
                </Col>
              </Row>
            </Box>

            <Box mt={2}>
              <Typography variant="h6" className="font-heavy">
                Payment Amounts
              </Typography>

              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Payment in lieu of notice:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel
                      amount={payTermination.payment_in_lieu_amount}
                    />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Amount of unused holiday/annual leave:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.unused_holiday_amount} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Amount of unused leave loading:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel
                      amount={payTermination.unused_leave_loading_amount}
                    />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Amount of unused long service leave:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.unused_lsl_amount} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>
                      Amount of unused sick/personal/carer's leave:
                    </strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.unused_sick_amount} />
                  </Typography>
                </Col>
              </Row>
            </Box>
            <Box mt={2} mb={5}>
              <Typography variant="h6" className="font-heavy">
                Total
              </Typography>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Total Employee Termination Payment:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.total_amount} />
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Typography variant="body1">
                    <strong>Employee Termination Payment Tax:</strong>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    <MoneyLabel amount={payTermination.tax_amount} />
                  </Typography>
                </Col>
              </Row>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default SummaryView;
