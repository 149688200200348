import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import AdminContext from "../../AdminContext";
import qs from "qs";
import { BiCloudDownload, BiArrowBack, BiCommentError } from "react-icons/bi";
import { IconContext } from "react-icons";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import QuestionModal from "../../components/Widgets/QuestionModal";

const SuperStreamMailboxMessage = (props) => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSuperStreamMessage, setCurrentSuperStreamMessage] =
    useState(false);
  const [superFundDeposits, setSuperFundDeposits] = useState([]);
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [pdfFileData, setPdfFileData] = useState(null);
  const [summaryGeneral, setSummaryGeneral] = useState("");
  const [summarySpecific, setSummarySpecific] = useState("");
  const [summaryBreakDownLabel, setSummaryBreakDownLabel] = useState("");
  const [summaryBreakDownAmount, setSummaryBreakDownAmount] = useState("");
  const [depositsTableVisible, setDepositsTableVisible] = useState(true);
  const [confirmingManuallyFail, setConfirmingManuallyFail] = useState(false);
  const [manuallyFailEnabled, setManuallyFailEnabled] = useState(false);

  const currentSuperStreamMessageID = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/super-fund-deposits/superstream/mailbox/message/" +
        currentSuperStreamMessageID,
      (response) => {
        if (response.data.superstream_message) {
          setCurrentSuperStreamMessage(response.data.superstream_message);
        }
        if (response.data.responses) {
          setResponses(response.data.responses);
        }
        if (response.data.selected_response) {
          setSelectedResponse(response.data.selected_response.response);
        }
        if (response.data.pdf_file_data) {
          setPdfFileData(response.data.pdf_file_data);
        }
        if (response.data.contribution_deposits) {
          setSuperFundDeposits(response.data.contribution_deposits);
        }
        if (response.data.summary_general) {
          setSummaryGeneral(response.data.summary_general);
        }
        if (response.data.summary_specific) {
          setSummarySpecific(response.data.summary_specific);
        }
        if (response.data.summary_breakdown_label) {
          setSummaryBreakDownLabel(response.data.summary_breakdown_label);
        }
        if (response.data.summary_breakdown_amount) {
          setSummaryBreakDownAmount(response.data.summary_breakdown_amount);
        }
        if (response.data.deposits_table_visible) {
          setDepositsTableVisible(response.data.deposits_table_visible);
        }
        if (response.data.manually_fail_enabled) {
          setManuallyFailEnabled(response.data.manually_fail_enabled);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const downloadPDF = async () => {
    const fileName = props.name + ".pdf";
    await adminContext.handlePDFDownload(fileName, pdfFileData);
  };

  const manuallyFail = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        "/super-fund-deposits/superstream/mailbox/message/" +
        currentSuperStreamMessageID +
        "/manually-fail",
      {},
      (response) => {
        if (response.data.superstream_message) {
          setCurrentSuperStreamMessage(response.data.superstream_message);
        }
        initView();
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <>
                <h1>Message Information</h1>
                <Row>
                  <Col md="2">
                    <Button
                      onClick={() => {
                        adminContext.history.goBack();
                      }}
                      className="my-4 mx-auto semi-active-button"
                      color="secondary"
                      type="button"
                    >
                      <IconContext.Provider value={{ size: "30px" }}>
                        <BiArrowBack />
                      </IconContext.Provider>
                      <span className="ml-1">Back</span>
                    </Button>
                  </Col>
                  <Col md="2">
                    {pdfFileData && (
                      <Button
                        onClick={downloadPDF}
                        className="my-4 mx-2 semi-active-button"
                        color="info"
                        type="button"
                      >
                        <IconContext.Provider value={{ size: "30px" }}>
                          <BiCloudDownload />
                        </IconContext.Provider>
                        <span className="ml-1">Download PDF</span>
                      </Button>
                    )}
                  </Col>
                  <Col md="2">
                    <Button
                      disabled={
                        !manuallyFailEnabled ||
                        summaryGeneral?.includes("Manually Failed")
                      }
                      onClick={() => {
                        setConfirmingManuallyFail(true);
                      }}
                      className="my-4 mx-2 semi-active-button"
                      color="warning"
                      type="button"
                    >
                      <IconContext.Provider value={{ size: "30px" }}>
                        <BiCommentError />
                      </IconContext.Provider>
                      <span className="ml-1">Manually Fail</span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{ __html: summaryGeneral }}
                    ></div>
                  </Col>
                  <Col md="4">
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{ __html: summarySpecific }}
                    ></div>
                  </Col>
                  <Col>
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{
                        __html: summaryBreakDownLabel,
                      }}
                    ></div>
                  </Col>
                  <Col>
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{
                        __html: summaryBreakDownAmount,
                      }}
                    ></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TableWidget
                      title="Employee Records"
                      label="Employee Records"
                      rows={superFundDeposits || []}
                      columns={[
                        {
                          label: "Employee Last Name",
                          accessor: "employee_last_name",
                          widget: "Text",
                        },
                        {
                          label: "Employee First Name",
                          accessor: "employee_first_name",
                          widget: "Text",
                        },
                        {
                          label: "Paid On",
                          accessor: "paid_on",
                          widget: "Text",
                        },
                        {
                          label: "Super Levy",
                          accessor: "guarantee",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Member Contribution",
                          accessor: "member_contribution",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "RESC",
                          accessor: "resc",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Salary Sacrifice",
                          accessor: "salary_sacrifice",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Total Amount",
                          accessor: "total_amount",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Comment",
                          accessor: "comment",
                          widget: "Text",
                        },
                      ]}
                    />{" "}
                  </Col>
                </Row>
              </>
              <Row>
                <Col>
                  <TableWidget
                    title="ATO Responses (Errors/Warnings)"
                    label="ATO Responses (Errors/Warnings)"
                    rows={responses || []}
                    columns={[
                      {
                        label: "Received On",
                        accessor: "received",
                        widget: "DateLabel",
                      },
                      {
                        label: "Total Information",
                        accessor: "total_information",
                      },
                      {
                        label: "Total Errors",
                        accessor: "total_errors",
                      },
                      {
                        label: "Total Warnings",
                        accessor: "total_warnings",
                      },
                      {
                        label: "Max Severity",
                        accessor: "max_severity",
                      },
                    ]}
                  />
                </Col>
              </Row>
              {selectedResponse && selectedResponse.event_items && (
                <>
                  <Row>
                    <Col>
                      <TableWidget
                        title="Response Details"
                        label="Response Details"
                        rows={selectedResponse.event_items || []}
                        columns={[
                          {
                            label: "Message",
                            accessor: "short",
                          },
                          {
                            label: "Code",
                            accessor: "error",
                          },
                          {
                            label: "Severity",
                            accessor: "severity",
                          },
                          {
                            label: "More Details",
                            accessor: "detailed",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <QuestionModal
                isOpen={confirmingManuallyFail}
                title="Confirm marking message as failed"
                content={
                  <>
                    <p>Are you sure you wish to mark this message as failed?</p>
                    <p>
                      Marking this message as failed means that you would like
                      to release the deposits in this message so that they may
                      be sent in a new message. This is usually required in the
                      case where a fund has refunded the money due to an error
                      or when the original message was never received for
                      whatever reason.
                    </p>
                  </>
                }
                onConfirm={() => {
                  setConfirmingManuallyFail(false);
                  manuallyFail();
                }}
                onDeny={() => {
                  setConfirmingManuallyFail(false);
                }}
              />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SuperStreamMailboxMessage;
