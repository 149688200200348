import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  CustomInput,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import DateEdit from "../../components/Widgets/DateEdit";
import MoneyLabel from "../../components/Widgets/MoneyLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import ListWidget from "../../components/Widgets/ListWidget";
import BackButton from "../../components/Widgets/BackButton";
import AdminContext from "../../AdminContext";
import dayjs from "dayjs";

const CreateMultipleSuperFundDeposits = () => {
  // Sample state hooks
  const adminContext = useContext(AdminContext);
  const [dateOption, setDateOption] = useState("this_month");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [useProcessedDate, setUseProcessedDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [amounts, setAmounts] = useState({});
  const timerRef = useRef(null);
  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [zeroDollarDeposits, setZeroDollarDeposits] = useState([]);
  const [noActiveFundEmployees, setNoActiveFundEmployees] = useState([]);

  const [selectedRows, setSelectedRows] = useState({
    employees: adminContext.company.employees,
  });

  const areEmployeesSame = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.slice().sort((a, b) => a.id - b.id);
    const sortedArr2 = arr2.slice().sort((a, b) => a.id - b.id);

    return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
  };

  const updateForm = (form, updateParameters) => {
    console.log("updating form");
    if (updateParameters === true) {
      setUseProcessedDate(form.use_processed_date);
      setStartDate(form.start_date);
      setEndDate(form.end_date);
    }

    // Only update employees if they are different
    setRows({ employees: form.employees });

    if (!areEmployeesSame(form.selected_employees, selectedRows.employees)) {
      setSelectedRows({ employees: form.selected_employees });
    }

    setAmounts({
      total_super_guarantee: form.total_super_guarantee,
      total_sacrifice: form.total_sacrifice,
      total_additional_resc: form.total_additional_resc,
      total_post_tax: form.total_post_tax,
      total_super: form.total_super,
      total_no_active_fund: form.total_no_active_fund,
      total_payable: form.total_payable,
    });
  };

  const refreshView = () => {
    // Occasionally the company ID can be mid state change so we need to check if it's undefined
    if (adminContext.company.id === undefined) return;

    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/create-deposits/refresh?company_id=${adminContext.company.id}`,
      {
        start_date: startDate,
        end_date: endDate,
        use_processed_date: useProcessedDate,
        selected_employees: selectedRows.employees,
      },
      (response) => {
        if (response.data.form) {
          updateForm(response.data.form, false);
        }
      }
    );
  };

  const getRows = () => {
    let updatedRows = { ...rows };
    // If "no active fund" is a substring of the format_name (matched on lower case), set row.text_danger to true
    updatedRows.employees = updatedRows.employees.map((emp) => {
      if (
        emp.format_name &&
        emp.format_name.toLowerCase().includes("no active fund")
      ) {
        emp.text_danger = true;
      } else {
        emp.text_danger = false;
      }
      return emp;
    });
    return updatedRows;
  };

  const createDeposits = () => {
    // Occasionally the company ID can be mid state change so we need to check if it's undefined
    if (adminContext.company.id === undefined) return;

    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/create-deposits/create?company_id=${adminContext.company.id}`,
      {
        start_date: startDate,
        end_date: endDate,
        use_processed_date: useProcessedDate,
        selected_employees: selectedRows.employees,
      },
      (response) => {
        setIsLoading(false);
        if (response.data.form.new_deposits) {
          let depositsWithAnAmount = [];

          response.data.form.new_deposits.forEach((deposit) => {
            if (deposit.total_amount._decimal > 0) {
              depositsWithAnAmount.push(deposit);
            }
          });

          adminContext.setNewSuperFundDeposits(depositsWithAnAmount);
          adminContext.history.push("/admin/super_fund_deposits");
        }
      }
    );
  };

  const checkDepositsPriorToCreation = () => {
    // We need to show a warning modal if deposits have been created without any amount or when the employee has no active fund.
    let newNoActiveFundEmps = [];
    let newZeroDollarDeposits = [];
    getRows().employees.forEach((emp) => {
      // Ensure the row is in the selected rows, otherwise ignore it
      if (!selectedRows.employees.find((e) => e.id === emp.id)) return;

      if (emp.text_danger) {
        newNoActiveFundEmps.push(emp);
      }
      if (emp.format_name.toLowerCase().includes("$0.00")) {
        newZeroDollarDeposits.push(emp);
      }
    });

    setNoActiveFundEmployees(newNoActiveFundEmps);
    setZeroDollarDeposits(newZeroDollarDeposits);

    if (!newNoActiveFundEmps.length && !newZeroDollarDeposits.length) {
      createDeposits();
    }
  };

  useEffect(() => {
    // Clear any previously set timer before setting a new one.
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a timer that listens to changes in selectedRows.employees.
    timerRef.current = setTimeout(() => {
      refreshView();

      // Clear the timer.
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }, 1000);

    return () => {
      // Cleanup the timer when the component is unmounted or when selectedRows.employees changes.
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [selectedRows.employees]);

  useEffect(() => {
    const init = () => {
      adminContext.postRequest(
        adminContext.constants.BASE_URL +
          `/super-fund-deposits/create-deposits/init?company_id=${adminContext.company.id}`,
        {},
        (response) => {
          if (response.data.form) {
            updateForm(response.data.form, true);
          }
          setIsLoading(false);

          // No need to refresh the view immediately after loading the page.
          setTimeout(() => {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
          }, 3000);
        }
      );
    };
    init();
  }, []);

  // Call refresh when date options are changed
  useEffect(() => {
    console.log("Refreshing view");
    refreshView();
  }, [startDate, endDate, useProcessedDate]);

  // Update the startDate and endDate when the dateOption is changed
  useEffect(() => {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    let startMonth, endMonth;
    switch (dateOption) {
      case "this_month":
        startMonth = currentMonth;
        endMonth = currentMonth;
        break;
      case "last_month":
        startMonth = currentMonth - 1;
        endMonth = currentMonth - 1;
        break;
      case "this_quarter":
        if (currentMonth < 3) {
          // Q1: Jan - Mar
          startMonth = 0;
          endMonth = 2;
        } else if (currentMonth < 6) {
          // Q2: Apr - Jun
          startMonth = 3;
          endMonth = 5;
        } else if (currentMonth < 9) {
          // Q3: Jul - Sep
          startMonth = 6;
          endMonth = 8;
        } else {
          // Q4: Oct - Dec
          startMonth = 9;
          endMonth = 11;
        }
        break;
      case "last_quarter":
        if (currentMonth < 3) {
          // Previous Q4: Oct - Dec
          startMonth = 9;
          endMonth = 11;
          currentYear--; // Decrement the year as we're referring to the last year's Q4
        } else if (currentMonth < 6) {
          // Previous Q1: Jan - Mar
          startMonth = 0;
          endMonth = 2;
        } else if (currentMonth < 9) {
          // Previous Q2: Apr - Jun
          startMonth = 3;
          endMonth = 5;
        } else {
          // Previous Q3: Jul - Sep
          startMonth = 6;
          endMonth = 8;
        }
        break;
      default:
        return; // For custom dates or any other cases, we don't change anything
    }
    const startDay = new Date(currentYear, startMonth, 1, 12); // Set to noon
    setStartDate(startDay.toISOString().split("T")[0]);

    // To get the last day of the endMonth, we'll compute the first day of the next month and then subtract one day.
    const lastDay = new Date(currentYear, endMonth + 1, 1, 12); // Set to noon
    lastDay.setDate(lastDay.getDate() - 1);
    setEndDate(lastDay.toISOString().split("T")[0]);
  }, [dateOption]);

  const handleDateChange = (startOrEnd, dateTime) => {
    const date = dayjs(dateTime, "YYYY-MM-DD", true);
    if (!date.isValid()) {
      return;
    }

    if (startOrEnd === "start") {
      setStartDate(dateTime);
      const end = dayjs(endDate, "YYYY-MM-DD", true);
      if (!end.isValid() || date.isAfter(end)) {
        setEndDate(dateTime);
      }
    } else {
      setEndDate(dateTime);
      const start = dayjs(startDate, "YYYY-MM-DD", true);
      if (!start.isValid() || date.isBefore(start)) {
        setStartDate(dateTime);
      }
    }
  };

  return (
    <Container fluid>
      <Card className="bg-secondary shadow ml-2 mr-3 ">
        {isLoading ? (
          <LoadingSpinner width="40%" height="40%" />
        ) : (
          <>
            <Row>
              <Col md="2" className="text-center">
                <BackButton className="m-2" />
              </Col>
              <Col md="6">
                <h1 className="days-one m-3">Create Super Deposits</h1>
              </Col>
            </Row>

            {/* Date Option Selection */}
            <Card>
              <CardHeader>Select a date option</CardHeader>
              <CardBody>
                <p>
                  You can choose to use one of the pre-defined date ranges below
                  or use a custom date range.
                </p>
                <Row>
                  <Col>
                    <CustomInput
                      type="radio"
                      id="this_month"
                      label="This month"
                      name="dateOption"
                      checked={dateOption === "this_month"}
                      onChange={() => setDateOption("this_month")}
                    />
                    <CustomInput
                      type="radio"
                      id="last_month"
                      label="Last month"
                      name="dateOption"
                      checked={dateOption === "last_month"}
                      onChange={() => setDateOption("last_month")}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      type="radio"
                      id="this_quarter"
                      label="This quarter"
                      name="dateOption"
                      checked={dateOption === "this_quarter"}
                      onChange={() => setDateOption("this_quarter")}
                    />
                    <CustomInput
                      type="radio"
                      id="last_quarter"
                      label="Last quarter"
                      name="dateOption"
                      checked={dateOption === "last_quarter"}
                      onChange={() => setDateOption("last_quarter")}
                    />
                  </Col>
                  <Col className="spacer-column"> </Col>
                </Row>
                <CustomInput
                  type="radio"
                  id="custom"
                  label="Custom dates"
                  name="dateOption"
                  checked={dateOption === "custom"}
                  onChange={() => setDateOption("custom")}
                />
              </CardBody>
            </Card>

            {/* Include Pays Between */}
            <Card>
              <CardHeader>Include Pays Between</CardHeader>
              <CardBody>
                <Form inline>
                  <Row>
                    <Col>
                      <DateEdit
                        label="Start Date"
                        value={startDate}
                        disabled={dateOption !== "custom"}
                        onChange={(date) => handleDateChange("start", date)}
                        type="date"
                        name="start_date"
                      />
                    </Col>
                    <Col>
                      <DateEdit
                        label="End Date"
                        value={endDate}
                        disabled={dateOption !== "custom"}
                        onChange={(date) => handleDateChange("end", date)}
                        type="date"
                        name="start_date"
                      />
                    </Col>
                  </Row>
                </Form>
                <FormControlLabel
                  label="Use processed date?"
                  control={
                    <CheckBox
                      checked={useProcessedDate}
                      color="primary"
                      id="mastercheck"
                      onChange={(e) => setUseProcessedDate(e.target.checked)}
                    />
                  }
                />
              </CardBody>
            </Card>

            {/* Employees and Totals */}
            <Row>
              {/* Employees Group */}
              <Col lg="8">
                <Card>
                  <CardHeader>
                    <div className="text-center">
                      <strong>Select Employees</strong>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <ListWidget
                      name="employees"
                      rows={getRows()}
                      setRows={setRows}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  </CardBody>
                </Card>
              </Col>

              {/* Totals Group */}
              <Col>
                <Card>
                  <CardHeader>
                    <div className="text-center">
                      <strong>Total Selected</strong>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      
                      <Row>
                        <Col md="8">Super Guarantee</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_super_guarantee} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">Sacrifice RESC</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_sacrifice} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">Additional RESC</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_additional_resc} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">Voluntary/Post-Tax</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_post_tax} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">
                          <strong>Total (All Selected)</strong>
                        </Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_super} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">
                          <strong className="text-red">
                            Total (No Active Fund)
                          </strong>
                        </Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_no_active_fund} />
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">
                          <strong>Total Payable (With Active Fund)</strong>
                        </Col>
                        <Col className="text-right">
                          <MoneyLabel amount={amounts.total_payable} />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <div className="text-center">
                  <Button
                    className="semi-active-button my-2 width-80-on-lg mx-auto"
                    color="success"
                    disabled={
                      !selectedRows.employees ||
                      selectedRows.employees.length === 0
                    }
                    onClick={checkDepositsPriorToCreation}
                  >
                    <i
                      className="fas fa-plus-circle fa-xl
                       "
                    ></i>{" "}
                    <br />
                    <span className="text-lg">Create</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Modal
        isOpen={
          noActiveFundEmployees.length > 0 || zeroDollarDeposits.length > 0
        }
        centered={true}
      >
        <div className="mx-3 my-4">
          <h3>Additional Deposit Information</h3>
          {noActiveFundEmployees.length > 0 && (
            <>
              <p>
                The following employees have no active fund and can not be
                included in a deposit:
              </p>
              <ul>
                {noActiveFundEmployees.map((emp) => (
                  <li key={emp.id}>
                    {adminContext.toTitleCase(emp.format_name)}
                  </li>
                ))}
              </ul>
            </>
          )}
          {zeroDollarDeposits.length > 0 && (
            <>
              <p>
                The following employees have no unallocated super in the
                selected date range.
                <br /> A $0.00 deposit will be created for each of these records
                but they will not be included in a SuperStream Message:
              </p>
              <ul>
                {zeroDollarDeposits.map((emp) => (
                  <li key={emp.id}>
                    {adminContext.toTitleCase(emp.format_name)}
                  </li>
                ))}
              </ul>
            </>
          )}

          <Button
            className="semi-active-button"
            color="secondary"
            onClick={() => {
              setNoActiveFundEmployees([]);
              setZeroDollarDeposits([]);
              createDeposits();
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default CreateMultipleSuperFundDeposits;
