import AdminContext from "../../AdminContext";
import EditForm from "../../components/Widgets/EditForm";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody, Modal, Button } from "reactstrap";
import validateTFN from "../../validators/validateTFN";

const NewEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newEmployee, setNewEmployee] = useState({});
  const adminContext = useContext(AdminContext);
  const [tfnWarningOpen, setTfnWarningOpen] = useState(false);

  const getNewEmployee = async () => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/employees/new-employee/" +
        adminContext.company.id,
      (response) => {
        setNewEmployee(response.data.new_employee);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getNewEmployee();
  }, []);

  // Update the API on submit
  const saveChanges = () => {
    console.log(newEmployee.tax_file_number);
    if (!newEmployee.tax_file_number) {
      setTfnWarningOpen(true);
      return;
    }
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      newEmployee,
      (response) => {
        setIsLoading(false);
        adminContext.history.push("/admin/employees/pay_settings/pay_rates");
      }
    );
  };

  return (
    <>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Add New Employee</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EditForm
                      saveChanges={saveChanges}
                      liveEdit={true}
                      formSpec={{
                        title: "",
                        fields: [
                          {
                            label: "Personal Details",
                            widget: "SectionHeader",
                          },
                          {
                            label: "First Name",
                            widget: "Text",
                            accessor: "first_name",
                            required: true,
                            validate: {
                              required: {
                                value: true,
                                message: "First name is required",
                              },
                              minLength: { value: 1 },
                              maxLength: { value: 40 },
                            },
                          },
                          {
                            label: "Middle Name",
                            widget: "Text",
                            accessor: "middle_name",
                          },
                          {
                            label: "Last Name",
                            widget: "Text",
                            accessor: "last_name",
                            required: true,
                            validate: {
                              required: {
                                value: true,
                                message: "Last name is required",
                              },
                              minLength: { value: 1 },
                              maxLength: { value: 40 },
                            },
                          },
                          {
                            label: "Date of Birth",
                            widget: "DateEdit",
                            accessor: "date_of_birth",
                            required: true,
                          },
                          {
                            label: "Email",
                            accessor: "email_address",
                            widget: "Text",
                          },
                          {
                            label: "Phone (Home)",
                            accessor: "phone_home",
                            widget: "Text",
                          },
                          {
                            label: "Phone (Mobile)",
                            accessor: "phone_mobile",
                            widget: "Text",
                          },
                          {
                            label: "Employment Details",
                            widget: "SectionHeader",
                          },
                          {
                            label: "Employment Start Date",
                            accessor: "start_date",
                            widget: "DateEdit",
                            required: true,
                          },
                          {
                            label: "Tax File Number",
                            accessor: "tax_file_number",
                            widget: "Text",

                            args: {
                              validateCallback: (row) => {
                                if (!validateTFN(row.tax_file_number)) {
                                  return "Please enter a valid tax file number";
                                }
                                return "";
                              },
                              styleCallback: (row) => {
                                return validateTFN(
                                  row.tax_file_number
                                )
                                  ? "text-success"
                                  : "text-danger";
                              },
                            },
                          },
                          {
                            label: "Pay Method",
                            accessor: "pay_method",
                            widget: "ComboBox",
                            args: {
                              comboDataCallback: () => {
                                return {
                                  CASH: "Cash",
                                  "DIRECT BANK ENTRY": "Direct Bank Entry",
                                  OTHER: "Other",
                                };
                              },
                            },
                          },
                          {
                            label: "Pay Type",
                            accessor: "pay_type",
                            widget: "ComboBox",
                            args: {
                              comboDataCallback: () => {
                                return {
                                  SALARY: "Salary",
                                  WAGE: "Wage",
                                };
                              },
                            },
                          },
                          {
                            label: "Pay Period",
                            accessor: "pay_period",
                            widget: "ComboBox",
                            args: {
                              comboDataCallback: () => {
                                return {
                                  WEEKLY: "Weekly",
                                  FORTNIGHTLY: "Fortnightly",
                                  MONTHLY: "Monthly",
                                };
                              },
                            },
                          },
                          {
                            label: "ATO Employment Status",
                            accessor: "stp_employment_status",
                            widget: "ComboBox",
                            args: {
                              validateCallback: (row) => {
                                if (
                                  row.stp_employment_status === "Unclassified"
                                ) {
                                  return "Please select a valid ATO employment status from the dropdown";
                                }
                                return "";
                              },

                              comboDataCallback: () => {
                                return {
                                  F: "Full-Time",
                                  P: "Part-Time",
                                  C: "Casual",
                                  V: "Voluntary Agreement",
                                  D: "Death Beneficiary",
                                  Unclassified: "Unclassified",
                                };
                              },
                            },
                          },
                        ],
                      }}
                      cancelCallback={adminContext.history.goBack}
                      setModelObj={setNewEmployee}
                      modelObj={newEmployee}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
      <Modal isOpen={tfnWarningOpen}>
        <div className="mx-3 my-4">
          <h2>Tax File Number Required</h2>
          <p>
            If your employee has not yet provided a TFN, you can enter a
            temporary one from the following list.
          </p>
          <Container>
            <Row>
              <Col md="3">
                <strong>000000000</strong>
              </Col>
              <Col md="9">
                Use if a payee has not provided a TFN or fails to provide one
                within 14 days.{" "}
                <em>This will tax the employee at a higher rate.</em>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <strong>111111111</strong>
              </Col>
              <Col md="9">
                Use if a payee does not provide a valid TFN or you receive a
                declaration from the payee which says they have applied for a
                TFN. This code should be updated with the payee’s TFN, or, with
                the no TFN quoted code when the payee fails to provide the TFN
                to the payer within the 28-day period allowed.
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <strong>333333333</strong>
              </Col>
              <Col md="9">
                Use if a payee is under 18 years old and claims the general
                exemption because they don't earn enough for tax to be deducted.
                If circumstances change and the payee subsequently earns a level
                of income that attracts tax, a TFN will be required to be
                provided at that time.
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <strong>444444444</strong>
              </Col>
              <Col md="9">
                Use if the payee is a recipient of a social security or service
                pension or benefit (other than Newstart, sickness allowance,
                special benefits or partner allowance) and an exemption from
                quoting a TFN may be claimed.
              </Col>
            </Row>
          </Container>
          <Button
            color="secondary"
            className="float-right mb-2 mx-2"
            onClick={() => setTfnWarningOpen(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default NewEmployee;
