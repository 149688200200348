import React, { useState } from "react";
import { Table, Button } from "reactstrap";

const CustomerSummaryTable = ({ data, allUsageData }) => {
  // Group data by route and calculate counts
  const routeSummary = data.reduce((acc, log) => {
    acc[log.route] = (acc[log.route] || 0) + 1;
    return acc;
  }, {});

  // Convert the summary object to an array for sorting
  const summaryArray = Object.entries(routeSummary).map(([route, count]) => ({
    route,
    count,
  }));

  // Sort state
  const [sortConfig, setSortConfig] = useState({
    key: "count",
    direction: "desc",
  });

  // State to toggle row visibility
  const [showRows, setShowRows] = useState(true);

  // Sort data based on sortConfig
  const sortedData = [...summaryArray].sort((a, b) => {
    if (sortConfig.key === "count") {
      return sortConfig.direction === "asc"
        ? a.count - b.count
        : b.count - a.count;
    } else if (sortConfig.key === "route") {
      return sortConfig.direction === "asc"
        ? a.route.localeCompare(b.route)
        : b.route.localeCompare(a.route);
    }
    return 0;
  });

  // Handle sort toggle
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "desc"
          ? "asc"
          : "desc",
    }));
  };

  return (
    <div>
      <h2 className="mt-4">
        Showing {showRows ? Object.keys(sortedData).length : 0} {"/"}{" "}
        {Object.keys(allUsageData).length} web addresses accessed
      </h2>
      <Button
        color="primary"
        onClick={() => setShowRows((prev) => !prev)}
        className="mb-3"
      >
        {showRows ? "Hide Rows" : "Show Rows"}
      </Button>
      <Table striped bordered>
        <thead>
          <tr>
            <th>#</th>
            <th
              onClick={() => handleSort("route")}
              style={{ cursor: "pointer" }}
            >
              Route{" "}
              {sortConfig.key === "route" &&
                (sortConfig.direction === "asc" ? "▲" : "▼")}
            </th>
            <th
              onClick={() => handleSort("count")}
              style={{ cursor: "pointer" }}
            >
              Request Count{" "}
              {sortConfig.key === "count" &&
                (sortConfig.direction === "asc" ? "▲" : "▼")}
            </th>
          </tr>
        </thead>
        {showRows && (
          <tbody>
            {sortedData.map(({ route, count }, index) => (
              <tr key={route}>
                <td>{index + 1}</td>
                <td>{route}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default CustomerSummaryTable;
