import { Button, Modal, Container, Row, Col } from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { useState, useContext } from "react";
import AdminContext from "../../AdminContext";
import {
  FaPlus,
  FaUpload,
  FaCopy,
  FaUndo,
  FaTrash,
  FaUser,
  FaUsers,
} from "react-icons/fa";

/** Reusable Action Button Component */
const ActionButton = ({ id, color, onClick, iconLeft, label }) => {
  return (
    <Button
      id={id}
      color={color}
      onClick={onClick}
      className="w-100 semi-active-button my-1 d-flex align-items-center "
    >
      {iconLeft}&nbsp;&nbsp;&nbsp;
      <span className="w-100 text-center">{label}</span>
    </Button>
  );
};

const ActionsButtonGrid = () => {
  const adminContext = useContext(AdminContext);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [secondDeleteConfirmationOpen, setSecondDeleteConfirmationOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteEmployee = () => {
    setIsLoading(true);
    adminContext.deleteRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}`,
      () => {
        adminContext.setCurrentEmployee({});
        setIsLoading(false);
        closeModals();
      }
    );
  };

  const closeModals = () => {
    setDeleteConfirmationOpen(false);
    setSecondDeleteConfirmationOpen(false);
  };

  return (
    <div className="my-3 w-100">
      <h2 className="text-center days-one">Actions</h2>
      {!isLoading ? (
        <Container>
          <Row className="my-2">
            <Col>
              <ActionButton
                id="addEmployee"
                color="primary"
                onClick={() =>
                  adminContext.history.push("/admin/employees/new_employee")
                }
                iconLeft={<FaPlus size={20} />}
                label="Add New Employee"
              />
            </Col>
          </Row>

          <Row className="my-2">
            <Col>
              <ActionButton
                id="importEmployee"
                color="primary"
                onClick={() =>
                  adminContext.history.push("/admin/employees/import_employees")
                }
                iconLeft={<FaUpload size={20} />}
                label="Import From CSV"
              />
            </Col>
          </Row>

          {adminContext.companies.length > 1 &&
            adminContext.currentEmployee?.id && (
              <Row className="my-2">
                <Col>
                  <ActionButton
                    id="copyEmployee"
                    color="info"
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/employees/copy_employee"
                      )
                    }
                    iconLeft={<FaCopy size={20} />}
                    label="Copy Employee"
                  />
                </Col>
              </Row>
            )}

          {adminContext.currentEmployee?.is_terminated && (
            <Row className="my-2">
              <Col>
                <ActionButton
                  id="reinstateEmployee"
                  color="success"
                  onClick={() =>
                    adminContext.history.push("/admin/employees/reinstate")
                  }
                  iconLeft={<FaUndo size={20} />}
                  label="Reinstate Employee"
                />
              </Col>
            </Row>
          )}

          {adminContext.currentEmployee?.id && (
            <Row className="my-2">
              <Col>
                <ActionButton
                  id="deleteEmployee"
                  color="danger"
                  onClick={() => setDeleteConfirmationOpen(true)}
                  iconLeft={<FaTrash size={20} />}
                  label="Delete Employee"
                />
              </Col>
            </Row>
          )}
        </Container>
      ) : (
        <div className="text-center">
          <LoadingSpinner size="3rem" />
          <p className="text-danger">Deleting employee profile...</p>
        </div>
      )}

      {/* Confirmation Modals */}
      <Modal isOpen={secondDeleteConfirmationOpen}>
        <div className="mx-4 my-4">
          <p>
            This action cannot be undone. Are you sure you wish to erase, and
            not terminate?
          </p>
          <p>
            If you only wish to terminate the employee, do this in the{" "}
            <strong>PAYS</strong> screen. Terminating an employee will keep
            their records and simply formalize their exit from the company.
          </p>
          <p className="font-heavy">
            Are you absolutely sure you wish to completely erase all history for
            this employee?
          </p>
          <Button
            color="warning"
            className="float-right mb-2 mx-2"
            onClick={deleteEmployee}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            className="float-right mb-2 mx-2"
            onClick={closeModals}
          >
            No, cancel
          </Button>
        </div>
      </Modal>

      <Modal isOpen={deleteConfirmationOpen}>
        <div className="mx-4 my-4">
          <p>
            Are you sure you would like to delete the employee profile for{" "}
            <strong>
              {adminContext.currentEmployee?.first_name}{" "}
              {adminContext.currentEmployee?.last_name}
            </strong>
            ?
          </p>
          <p>
            The Australian Tax Office requires that electronic records are kept
            for at least seven years.
          </p>
          <Button
            color="warning"
            className="float-right mb-2 mx-2"
            onClick={() => setSecondDeleteConfirmationOpen(true)}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            className="float-right mb-2 mx-2"
            onClick={closeModals}
          >
            No, cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ActionsButtonGrid;
