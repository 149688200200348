import { useState, useContext, useEffect } from "react";
import AdminContext from "../../AdminContext";
import {
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
  CardBody,
  Input,
  Container,
} from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TableWidget from "../../components/Widgets/TableWidget";
import QuestionModal from "../../components/Widgets/QuestionModal";

const LoginAsCustomer = () => {
  const adminContext = useContext(AdminContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [idToUse, setIdToUse] = useState(null);
  const [selectedFile, setSelectedFile] = useState();

  const initCustomers = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/customers",
      (response) => {
        if (response.data.customers) {
          setCustomers(response.data.customers);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    initCustomers();
  }, []);

  const handleSubmission = (file, customerRow) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("file", file);
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/admin/backup/restore/${customerRow.customer_id}`,
      formdata,
      (response) => {
        if (response.status === 200) {
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage(
            response.data.message || "Backup restored successfully."
          );
        } else {
          adminContext.setAlertColor("danger");
          adminContext.setAlertMessage(
            response.data.error ||
              "Something went wrong. Unable to restore backup."
          );
        }
        setLoading(false);
      }
    );
  };

  const loadCustomerData = (customer_id) => {
    setLoading(true);
    adminContext.setCompanies([]);
    adminContext.setCompany({});
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/customers/" + customer_id,
      (response) => {
        setLoading(false);
        adminContext.history.push("/admin/entities/select");
      }
    );
  };

  const flushCustomerData = () => {
    setLoading(true);
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL + "/admin/customers/" + idToUse,
      (response) => {
        setIdToUse(null);
        setLoading(false);
        alert("Customer DB and Log have been deleted.");
      }
    );
  };

  const downloadLog = (customer_id) => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/log/" + customer_id,
      (response) => {
        setLoading(false);
        console.log(response.data.log_data);
        const logData = "data:text/plain;base64," + response.data.log_data;

        const downloadLink = document.createElement("a");
        const fileName = "lp_web_app_" + customer_id + ".log";
        downloadLink.href = logData;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    );
  };

  const filteredCustomers = () => {
    if (searchQuery === "") {
      return customers;
    }
    return customers.filter((customer) => {
      // If there are search terms, filter the results searching for the terms within company_name, email, and primary_subscription_id

      // If the search term is a number, search for it within primary_subscription_id or the customer_id
      if (!isNaN(searchQuery)) {
        return (
          customer.primary_subscription_id
            .toString()
            .includes(searchQuery.toString()) ||
          customer.customer_id.toString().includes(searchQuery.toString())
        );
      }

      // Otherwise, search for the search term within company_name and email
      return (
        customer.company_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Form
                onSubmit={(e) => e.preventDefault()}
                className="d-md-flex ml-lg-auto mx-auto"
              >
                <FormGroup className="mx-auto border-1px width-40-on-lg">
                  <InputGroup className="input-group-alternative ">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          name="search-button"
                          className="fa-brands fa-searchengin fa-2xl text-yellow"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      placeholder="Search"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <h1 className="days-one text-center">Web App Customers</h1>
              <TableWidget
                rows={filteredCustomers()}
                columns={[
                  {
                    label: "Clone Data",
                    accessor: "clone_data",
                    iconOnly: true,
                    widget: "Button",
                    align: "right",

                    args: {
                      buttonIcon: "fa-regular fa-cloud-download fa-xl",
                      onClickCallback: (cust) =>
                        loadCustomerData(cust.customer_id),
                    },
                  },
                  {
                    label: "Usage Dashboard",
                    accessor: "usage_dashboard",
                    iconOnly: true,
                    widget: "Button",
                    align: "right",
                    args: {
                      buttonIcon: "fa-regular fa-line-chart fa-xl",
                      onClickCallback: (cust) =>
                        adminContext.history.push(
                          "/admin/customer-dashboard/" + cust.customer_id
                        ),
                    },
                  },
                  {
                    label: "Log",
                    accessor: "log",
                    iconOnly: true,
                    widget: "Button",
                    align: "right",

                    args: {
                      buttonIcon: "fa-regular fa-file-lines fa-xl",
                      onClickCallback: (cust) => downloadLog(cust.customer_id),
                    },
                  },
                  {
                    label: "Active?",
                    accessor: "status",
                    iconOnly: true,
                    widget: "CheckBox",
                    args: {
                      setRowCallback: (row) => {
                        setLoading(true);
                        adminContext.putRequest(
                          adminContext.constants.BASE_URL +
                            "/admin/update-status/" +
                            row.customer_id,
                          {
                            status: !row.status,
                          },
                          (response) => {
                            setLoading(false);
                            initCustomers();
                          }
                        );
                      },
                    },
                  },
                  {
                    label: "Auto Backups",
                    accessor: "auto_backups",
                    iconOnly: true,
                    widget: "Button",
                    align: "right",

                    args: {
                      buttonIcon: "fa-regular fa-download fa-xl",
                      onClickCallback: (cust) =>
                        adminContext.history.push(
                          "/admin/auto_backups?customer_id=" + cust.customer_id
                        ),
                    },
                  },

                  {
                    label: "Company",
                    accessor: "company_name",
                    widget: "text",
                  },
                  {
                    label: "Email",
                    accessor: "email",
                    widget: "text",
                  },
                  {
                    label: "Primary Subscription",
                    accessor: "primary_subscription_id",
                    widget: "text",
                  },
                  {
                    label: "Customer ID",
                    accessor: "customer_id",
                    widget: "text",
                  },
                  {
                    label: "Upload Backup",
                    accessor: "upload_backup",
                    args: {
                      buttonIcon: "fa-regular fa-cloud-upload fa-xl",
                      label: "Upload/Restore",
                      handleSubmission: handleSubmission,
                      setSelectedFile: setSelectedFile,
                      autoSubmit: true,
                    },
                    widget: "FileUploadButton",
                  },

                  {
                    label: "Flush Customer Data",
                    accessor: "flush_data",
                    iconOnly: true,
                    widget: "Button",
                    align: "right",

                    args: {
                      buttonIcon: "fa-regular fa-toilet-paper-xmark fa-xl",
                      onClickCallback: (cust) => setIdToUse(cust.customer_id),
                    },
                  },
                ]}
              />
            </>
          )}
        </CardBody>
      </Card>
      <QuestionModal
        isOpen={idToUse === null ? false : true}
        title=""
        content={
          <p>
            Are you sure you would like to delete the data for this customer?
          </p>
        }
        onConfirm={flushCustomerData}
        onDeny={() => {
          setIdToUse(null);
        }}
      />
    </Container>
  );
};

export default LoginAsCustomer;
