import React, { useState, useEffect } from "react";
import ListWidget from "../../components/Widgets/ListWidget";
import BackButton from "../../components/Widgets/BackButton";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import MoneyLabel from "../../components/Widgets/MoneyLabel";
import AdminContext from "../../AdminContext";
import { useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Button,
} from "reactstrap";
import { set } from "date-fns";

const PayDaySuperView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const [rows, setRows] = useState({
    pays: [],
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: [],
  });

  const initView = () => {
    setIsLoading(true);
    if (adminContext.company.id === undefined || !adminContext.company.id) {
      adminContext.history.push("/admin/entities/select");
      return;
    }
    let url =
      adminContext.constants.BASE_URL +
      `/super-fund-deposits/payday-super/init?company_id=${
        adminContext.company.id
      }&pay_run_date=${
        adminContext.payRun.end_date
      }&pay_period=${adminContext.payRun.pay_period.toUpperCase()}`;
    adminContext.getRequest(url, (response) => {
      setIsLoading(false);
      if (response.data.error) {
        adminContext.setAlertColor("error");
        adminContext.setAlertMessage(response.data.error);
        adminContext.history.push("/admin/pays");
      } else if (response.data.form) {
        setForm(response.data.form);
        if (response.data.form.pays) {
          setRows({
            pays: response.data.form.pays,
          });
        }
        if (response.data.form.selected_pays) {
          setSelectedRows({
            pays: response.data.form.selected_pays,
          });
        }
      }
    });
  };
  useEffect(() => {
    initView();
  }, []);

  const recalculateTotals = () => {
    let total_super_guarantee = 0;
    let total_sacrifice = 0;
    let total_additional_resc = 0;
    let total_post_tax = 0;
    let total_super = 0;
    let total_no_active_fund = 0;
    let total_unavailable_for_super = 0;
    let total_invalid_super_funds = 0;
    let total_payable = 0;

    selectedRows.pays.forEach((pay) => {
      const row = form.row_data[pay.id];

      // Calculate totals
      total_super_guarantee += pay.super_levy._decimal;
      total_sacrifice += pay.super_salary_sacrifice._decimal;
      total_additional_resc += pay.resc_super_amount._decimal;
      total_post_tax += pay.super_member_contribution._decimal;
      total_super += pay.super._decimal;
      if (!row.default_employee_fund) {
        total_no_active_fund += pay.super._decimal;
      } else if (row.invalid_super_fund_error) {
        total_invalid_super_funds += pay.super._decimal;
      } else if (!pay.available_for_super) {
        total_unavailable_for_super += pay.super._decimal;
      } else {
        total_payable += pay.super._decimal;
      }
    });

    setForm({
      ...form,
      total_super_guarantee,
      total_sacrifice,
      total_additional_resc,
      total_post_tax,
      total_super,
      total_no_active_fund,
      total_payable,
      total_unavailable_for_super,
      total_invalid_super_funds,
    });
  };

  useEffect(() => {
    recalculateTotals();
  }, [selectedRows]);

  const createDeposits = () => {
    setIsLoading(true);
    let url =
      adminContext.constants.BASE_URL +
      "/super-fund-deposits/payday-super/create";
    let data = {
      company_id: adminContext.company.id,
      selected_pays: selectedRows.pays.map((pay) => pay.id),
      pay_run_date: adminContext.payRun.end_date,
      pay_period: adminContext.payRun.pay_period.toUpperCase(),
    };
    adminContext.postRequest(url, data, (response) => {
      setIsLoading(false);
      if (response.data.error) {
        adminContext.setAlertColor("error");
        adminContext.setAlertMessage(response.data.error);
      } else {
        adminContext.setAlertColor("success");
        adminContext.setAlertMessage(response.data.message);
        adminContext.setNewSuperFundDeposits(response.data.new_deposits);
        adminContext.history.push("/admin/super_fund_deposits");
      }
    });
  };

  return (
    <Container fluid>
      <div className="m-1">
        <BackButton />
      </div>
      <Card className="bg-secondary shadow ml-2 mr-3 ">
        {isLoading || !form ? (
          <LoadingSpinner />
        ) : (
          <>
            <Row>
              <Col className="m-2">
                <h1 className="days-one m-3">Payday Super</h1>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: form.explain_label,
                    }}
                  />
                </p>
              </Col>
            </Row>
            {/* Employees and Totals */}
            <Row>
              {/* Employees Group */}
              <Col lg="8">
                <Card>
                  <CardHeader>
                    <div className="text-center">
                      <strong>Select Employees</strong>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <ListWidget
                      name="pays"
                      rows={rows}
                      setRows={setRows}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      rowTextColorCallback={(row) => {
                        if (
                          form.row_data &&
                          Object.keys(form.row_data).includes(
                            row.id.toString()
                          ) &&
                          form.row_data[row.id]["color"]
                        ) {
                          return form.row_data[row.id]["color"];
                        }
                        return "";
                      }}
                      formatNameCallback={(row) => {
                        if (
                          form.row_data &&
                          Object.keys(form.row_data).includes(
                            row.id.toString()
                          ) &&
                          form.row_data[row.id]["text"]
                        ) {
                          return form.row_data[row.id]["text"];
                        }
                        return row.name;
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>

              {/* Totals Group */}
              <Col>
                <Card>
                  <CardHeader>
                    <div className="text-center">
                      <strong>Total Selected</strong>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="8">Super Guarantee</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_super_guarantee} />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">Sacrifice RESC</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_sacrifice} />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">Additional RESC</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_additional_resc} />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">Voluntary/Post-Tax</Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_post_tax} />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">
                          <strong>Total (Selected)</strong>
                        </Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_super} />
                        </Col>
                      </Row>
                      {form.no_primary_fund_found ? (
                        <Row>
                          <Col md="8">
                            <strong className="text-error-red">
                              Total (No Active Fund)
                            </strong>
                          </Col>
                          <Col className="text-right">
                            <MoneyLabel amount={form.total_no_active_fund} />
                          </Col>
                        </Row>
                      ) : null}
                      {form.existing_deposits_found ? (
                        <Row>
                          <Col md="8">
                            <strong className="text-purple">
                              Total (Deposit Exists)
                            </strong>
                          </Col>
                          <Col className="text-right">
                            <MoneyLabel
                              amount={form.total_unavailable_for_super}
                            />
                          </Col>
                        </Row>
                      ) : null}
                      {form.invalid_fund_found ? (
                        <Row>
                          <Col md="8">
                            <strong className="text-dark-pink">
                              Total (Invalid USI/ESA)
                            </strong>
                          </Col>
                          <Col className="text-right">
                            <MoneyLabel
                              amount={form.total_invalid_super_funds}
                            />
                          </Col>
                        </Row>
                      ) : null}

                      <Row>
                        <Col md="8">
                          <strong>Total Payable</strong>
                        </Col>
                        <Col className="text-right">
                          <MoneyLabel amount={form.total_payable} />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <div className="text-center">
                  <Button
                    className="semi-active-button my-2 width-80-on-lg mx-auto"
                    color="success"
                    disabled={
                      !(
                        Array.isArray(selectedRows.pays) &&
                        selectedRows.pays.length > 0 &&
                        typeof form.total_payable === "number" &&
                        form.total_payable > 0
                      )
                    }
                    onClick={createDeposits}
                  >
                    <i
                      className="fas fa-plus-circle fa-xl
                       "
                    ></i>{" "}
                    <br />
                    <span className="text-lg">Create</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Container>
  );
};

export default PayDaySuperView;
