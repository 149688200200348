import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomAvField from "./CustomAvField";
import { Button, Alert, CardBody, Card } from "reactstrap";
import PercentageEdit from "./PercentageEdit";
import ComboBox from "./ComboBox";
import { useState, useEffect, useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoneyEdit from "./MoneyEdit";
import LeaveTakenTypeComboBox from "./LeaveTakenTypeComboBox";
import ListWidget from "./ListWidget";
import EmployeeListWidget from "./EmployeeListWidget";
import EmployeesSelect from "./EmployeesSelect";
import TimeEdit from "./TimeEdit";
import TimeLabel from "./TimeLabel";
import CheckBox from "@mui/material/Checkbox";
import DurationEdit from "./DurationEdit";
import DurationLabel from "./DurationLabel";
import AdminContext from "../../AdminContext";
import DateEdit from "./DateEdit";
import DateLabel from "./DateLabel";
import DateRangeEdit from "./DateRangeEdit";
import MoneyLabel from "./MoneyLabel";
import BooleanLabel from "./BooleanLabel";
import MultiplierEdit from "./MultiplierEdit";
import FormControl from "@mui/material/FormControl";
import InfoLabel from "./InfoLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TableWidget from "./TableWidget";

const EditForm = (props) => {
  // This object is used to store the temporary values of the form fields.
  // This is used to reset the form fields when the user clicks cancel.
  // On valid submit, the temporary values should be copied to the model object.
  // Set props.liveEdit to true if you want to instead update the model object directly.
  const [placeholderModelObj, setPlaceholderModelObj] = useState({
    ...props.modelObj,
  });

  const tempModelObj = props.liveEdit ? props.modelObj : placeholderModelObj;
  const setTempModelObj = props.liveEdit
    ? props.setModelObj
    : setPlaceholderModelObj;
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showTopButtons, setShowTopButtons] = useState(false);
  const adminContext = useContext(AdminContext);

  const submitForm = () => {
    if (props.liveEdit) {
      props.saveChanges();
    } else {
      setSubmitted(true);
      props.setModelObj(tempModelObj);
    }
  };

  const handleChange = (event) => {
    // Check for checkbox/switch
    if (event.target.type === "checkbox") {
      setTempModelObj({
        ...tempModelObj,
        [event.target.name]: event.target.checked,
      });
      return;
    }

    setTempModelObj({
      ...tempModelObj,
      [event.target.name]: event.target.value,
    });
  };

  // Run validation after tempModelObj is updated
  useEffect(() => {
    updateErrorText();
  }, [tempModelObj]);

  // Submit changes to the API
  useEffect(() => {
    if (submitted) {
      props.saveChanges();
      setSubmitted(false);
    }
  }, [props.modelObj]);

  const getPercentage = (accessor) => {
    return Math.round(Number(tempModelObj[accessor]) * 100 * 100) / 100;
  };

  const setPercentage = (accessor, percentage) => {
    let roundedPercentage = Math.round(percentage * 100) / 100 / 100;

    setTempModelObj({
      ...tempModelObj,
      [accessor]: roundedPercentage,
    });
  };

  const updateErrorText = () => {
    setErrorText("");

    // Validate form
    if (props.formSpec.validateFormCallback) {
      let error = props.formSpec.validateFormCallback(tempModelObj);
      if (error) {
        setErrorText(error);
        return;
      }
    }

    // Reversing the fields for validation makes errors show up in a top-down order which makes more sense.
    [...props.formSpec.fields].reverse().forEach((field) => {
      // Validate individual fields
      if (field.args && field.args.validateCallback) {
        let error = field.args.validateCallback(tempModelObj);
        if (error) {
          setErrorText(error);
          return;
        }
      }

      if (
        field.required === true &&
        (tempModelObj[field.accessor] === "" ||
          tempModelObj[field.accessor] === null)
      ) {
        setErrorText(field.label + " is required.");
        return;
      }

      // Validate date fields
      if (field.widget === "DatePicker") {
        if (field.required && tempModelObj[field.accessor] === null) {
          setErrorText(field.label + " is required.");
          return;
        }
      }

      // Next, validate unique fields before allowing saving
      // Note that siblings is props level and unique is field level.
      if (!props.siblings) {
        return;
      }
      if (field.unique) {
        props.siblings.forEach((sibling) => {
          if (
            sibling[field.accessor] === tempModelObj[field.accessor] &&
            sibling.id !== tempModelObj.id
          ) {
            setErrorText(field.label + " must be unique");
            return;
          }
        });
      }
    });
  };

  const handleDateChange = (label, accessor, dateTime) => {
    const dateObj = new Date(dateTime);
    if (isNaN(dateObj.getTime())) {
      setErrorText(label + " is invalid");
      return;
    }
    setTempModelObj({
      ...tempModelObj,
      [accessor]: dateTime,
    });
  };

  const updateButtonVisibility = () => {
    const screenHeight = window.innerHeight;
    const contentHeight = document.documentElement.scrollHeight;
    setShowTopButtons(contentHeight > 2 * screenHeight);
  };

  useEffect(() => {
    updateButtonVisibility();
    window.addEventListener("resize", updateButtonVisibility);
    return () => window.removeEventListener("resize", updateButtonVisibility);
  }, []);

  return (
    <Card className="shadow-sm">
      <CardBody>
        <AvForm className="mx-auto width-80-on-lg " onValidSubmit={submitForm}>
          <h1 className="mb-4 days-one text-center">
            {(() => {
              const { title } = props.formSpec;
              const isEdit = tempModelObj.id !== null;
              const fixedTitle = props.fixedTitle || props.formSpec.fixedTitle;

              let displayTitle = title;
              if (title && !fixedTitle) {
                displayTitle = isEdit ? `Edit ${title}` : `Add New ${title}`;
              }

              return displayTitle || "";
            })()}
          </h1>
          {props.formSpec.shortDescription ? (
            <p className="my-2">{props.formSpec.shortDescription}</p>
          ) : null}
          {errorText && (
            <Alert color="warning">
              <strong>{errorText}</strong>
            </Alert>
          )}
          {props.buttonsUpTop === true || showTopButtons ? (
            <>
              <hr />
              {props.saveChanges ? (
                <Button
                  className="btn-lg my-2 semi-active-button"
                  color="primary"
                  type="submit"
                  disabled={errorText.length > 0}
                >
                  {props.saveButtonText ? props.saveButtonText : "Save Changes"}
                </Button>
              ) : null}
              {props.cancelCallback ? (
                <Button
                  className="btn-lg my-2 semi-active-button"
                  color="secondary"
                  onClick={() => props.cancelCallback()}
                >
                  Cancel
                </Button>
              ) : null}
            </>
          ) : null}
          {props.formSpec.fields.map((field) => {
            if (
              field.args !== undefined &&
              field.args.visibleCallback !== undefined
            ) {
              if (!field.args.visibleCallback(tempModelObj)) return null;
            }
            if (
              field.args !== undefined &&
              field.args.visible !== undefined &&
              !field.args.visible
            ) {
              return null;
            }

            if (field.widget && field.widget.toLowerCase() === "textarea") {
              return (
                <div key={field.accessor}>
                  <label
                    className="form-control-label"
                    htmlFor={field.accessor}
                  >
                    {field.label}
                  </label>
                  <textarea
                    onChange={handleChange}
                    value={
                      field.valueCallback
                        ? field.valueCallback(tempModelObj)
                        : tempModelObj[field.accessor] || ""
                    }
                    type="text"
                    className="form-control mt-2 mb-3 width-100 min-height-200"
                    name={field.accessor}
                  />
                </div>
              );
            } else if (field.widget == "html") {
              return (
                <div
                  key={field.accessor}
                  className="my-2 width-100 min-height-200"
                  dangerouslySetInnerHTML={{
                    __html: tempModelObj[field.accessor],
                  }}
                />
              );
            } else if (field.widget === "TimeEdit") {
              // Sanitise the input which is usually a '17:00:00' 24 hr time string
              let timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
              if (timeRegex.test(tempModelObj[field.accessor])) {
                let time = tempModelObj[field.accessor].split(":");
                let dateTime = new Date();
                dateTime.setHours(time[0]);
                dateTime.setMinutes(time[1]);
                dateTime.setSeconds(time[2]);
                dateTime.setMilliseconds(0);
                setTempModelObj({
                  ...tempModelObj,
                  [field.accessor]: dateTime,
                });
              }
              return (
                <TimeEdit
                  key={field.accessor}
                  className="my-2"
                  label={field.label}
                  value={
                    tempModelObj[field.accessor]
                      ? tempModelObj[field.accessor]
                      : null
                  }
                  onChange={(time) =>
                    handleDateChange(field.label, field.accessor, time)
                  }
                />
              );
            } else if (field.widget === "TimeLabel") {
              return (
                <>
                  <div className="mt-3 mb-1">
                    <label>{field.label}</label>
                  </div>
                  <TimeLabel
                    className={"display-4 font-weight-bold"}
                    time={
                      tempModelObj[field.accessor]
                        ? tempModelObj[field.accessor]
                        : null
                    }
                  />
                </>
              );
            } else if (field.widget === "SectionHeader") {
              return <h2 key={field.label}>{field.label}</h2>;
            } else if (field.widget === "MoneyLabel") {
              return (
                <div key={field.accessor} className="form-group">
                  <div className="mt-3 mb-1">
                    <label>{field.label}</label>
                  </div>
                  <MoneyLabel
                    className={"display-4 font-weight-bold text-left"}
                    amount={field.valueCallback(tempModelObj)}
                  />
                </div>
              );
            } else if (field.widget === "TimeLabel") {
              return (
                <div key={field.accessor} className="form-group">
                  <div className="mt-3 mb-1">
                    <label>{field.label}</label>
                  </div>
                  <DurationLabel
                    className={"display-4 font-weight-bold"}
                    value={field.valueCallback(tempModelObj)}
                  />
                </div>
              );
            } else if (field.widget == "password") {
              return (
                <div key={field.accessor} className="form-group">
                  <label
                    className="form-control-label"
                    htmlFor={field.accessor}
                  >
                    {field.label}
                  </label>
                  <input
                    onChange={handleChange}
                    value={
                      field.valueCallback
                        ? field.valueCallback(tempModelObj)
                        : tempModelObj[field.accessor] || ""
                    }
                    type="password"
                    className="form-control mt-2 mb-3 width-100"
                    name={field.accessor}
                  />
                </div>
              );
            } else if (field.widget === "GroupBox") {
              return (
                <div key={field.accessor} className="form-group ml-2">
                  {field.label && (
                    <div className="mx-2 mb-1 mt-4">
                      <InfoLabel
                        label={field.label}
                        infoText={field.infoText || ""}
                        className={field.args.className}
                      />
                    </div>
                  )}

                  <FormControl component="fieldset">
                    <RadioGroup
                      value={tempModelObj[field.accessor]}
                      onChange={(event) => {
                        setTempModelObj({
                          ...tempModelObj,
                          [field.accessor]: event.target.value,
                        });
                      }}
                    >
                      {field.args.children.map((child) => (
                        <FormControlLabel
                          key={child.accessor}
                          value={child.accessor}
                          control={<Radio />}
                          label={child.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              );
            } else if (field.widget === "DurationLabel") {
              return (
                <div key={field.accessor} className="form-group">
                  <div className="mt-3 mb-1">
                    <label>{field.label}</label>
                  </div>
                  <DurationLabel
                    className={"display-4 font-weight-bold"}
                    value={
                      field.valueCallback !== undefined
                        ? field.valueCallback(tempModelObj)
                        : tempModelObj[field.accessor]
                    }
                  />
                </div>
              );
            } else if (field.widget === "DurationEdit") {
              return (
                <div key={field.accessor} className="form-group">
                  <div className="my-1">
                    <label>{field.label}</label>
                  </div>
                  <DurationEdit
                    value={tempModelObj[field.accessor]}
                    setValue={(value) => {
                      setTempModelObj({
                        ...tempModelObj,
                        [field.accessor]: value,
                      });
                    }}
                  />
                </div>
              );
            } else if (field.widget === "DateEdit") {
              return (
                <div key={field.accessor} className="my-4">
                  <DateEdit
                    label={field.label}
                    delay={field.delay || 0}
                    value={tempModelObj[field.accessor]}
                    onChange={(time) =>
                      handleDateChange(field.label, field.accessor, time)
                    }
                    minDate={field.args !== undefined && field.args.minDate}
                  />
                </div>
              );
            } else if (field.widget === "DateLabel") {
              return (
                <div key={field.accessor} className="form-group">
                  <div className="my-1">
                    <label>{field.label}</label>
                  </div>
                  <DateLabel
                    className={"display-4 font-weight-bold"}
                    label={field.label}
                    value={tempModelObj[field.accessor]}
                  />
                </div>
              );
            } else if (field.widget === "DateRangeEdit") {
              return (
                <div key={field.accessor} className="my-4">
                  <DateRangeEdit
                    startDateProps={{
                      label: field.startLabel,
                      value: tempModelObj[field.startAccessor],
                      onChange: (time) =>
                        handleDateChange(
                          field.startLabel,
                          field.startAccessor,
                          time
                        ),
                      minDate:
                        field.startArgs !== undefined &&
                        field.startArgs.minDate,
                    }}
                    endDateProps={{
                      label: field.endLabel,
                      value: tempModelObj[field.endAccessor],
                      onChange: (time) =>
                        handleDateChange(
                          field.endLabel,
                          field.endAccessor,
                          time
                        ),
                      minDate:
                        field.endArgs !== undefined && field.endArgs.minDate,
                    }}
                  />
                </div>
              );
            } else if (field.widget === "Button") {
              return (
                <Button
                  key={field.accessor}
                  className={
                    field.args.className + " btn-lg my-2 semi-active-button"
                  }
                  disabled={
                    field.args.disabled !== undefined && field.args.disabled
                  }
                  color={field.args.color}
                  onClick={(e) =>
                    field.args.onClick(e, tempModelObj, setTempModelObj)
                  }
                >
                  {field.args.icon ? (
                    <>
                      <i className={field.args.icon} />{" "}
                    </>
                  ) : null}
                  {field.args.text}
                </Button>
              );
            } else if (field.widget === "Label") {
              let value;
              if (field.valueCallback !== undefined) {
                value = field.valueCallback(tempModelObj);
              } else {
                value = tempModelObj[field.accessor];
              }
              return (
                <div key={field.accessor} className="form-group">
                  <div className="mt-3 mb-1">
                    <label>{field.label}</label>
                  </div>
                  <div className="display-4 font-weight-bold">{value}</div>
                </div>
              );
            } else if (field.widget === "ReadOnlyText") {
              return (
                <AvField
                  key={field.accessor}
                  name={field.accessor}
                  label={field.label}
                  type={field.widget}
                  disabled={true}
                  value={tempModelObj[field.accessor]}
                />
              );
            } else if (field.widget === "TableWidget") {
              return (
                <TableWidget
                  key={field.accessor}
                  label={field.label}
                  rows={tempModelObj[field.accessor]}
                  setRows={(rows) => {
                    setTempModelObj({
                      ...tempModelObj,
                      [field.accessor]: rows,
                    });
                  }}
                  columns={field.args.columns}
                  formSpec={field.args.formSpec}
                  appendRowCallback={field.args.appendRowCallback}
                  editRowSaveCallback={field.args.editRowSaveCallback}
                  deleteRowCallback={field.args.deleteRowCallback}
                  rowStyleCallback={field.args.rowStyleCallback}
                  deleteConfirmationAttributes={
                    field.args.deleteConfirmationAttributes
                  }
                />
              );
            } else if (
              field.widget === "ListWidget" ||
              field.widget === "EmployeeListWidget"
            ) {
              const WidgetComponent =
                field.widget === "ListWidget" ? ListWidget : EmployeeListWidget;

              return (
                <div key={field.accessor}>
                  <h3>{field.label}</h3>
                  {field.args.shortDescription ? (
                    <p>{field.args.shortDescription}</p>
                  ) : null}
                  <WidgetComponent
                    key={field.accessor}
                    shortDescription={field.args.shortDescription}
                    rows={field.args.rows}
                    filterCallback={field.args.filterCallback}
                    sortCallback={field.args.sortCallback}
                    setRows={field.args.setRows}
                    name={field.accessor}
                    disabled={field.args.disabled}
                    skipFilter={field.args.skipFilter}
                    noTitleCase={field.args.noTitleCase}
                    selectedRows={field.args.selectedRows}
                    setSelectedRows={field.args.setSelectedRows}
                    onChange={updateErrorText}
                    rowStyleCallback={field.args.rowStyleCallback}
                    formatNameCallback={field.args.formatNameCallback}
                  />
                </div>
              );
            } else if (field.widget === "EmployeesSelect") {
              return (
                <EmployeesSelect
                  className="text-left float-left"
                  title="Please select an employee"
                  formatNameCallback={field.args.formatNameCallback}
                />
              );
            } else if (field.widget === "MoneyEdit") {
              return (
                <MoneyEdit
                  key={field.accessor}
                  name={field.accessor}
                  decimalPrecision={
                    field.args !== undefined && field.args.decimalPrecision
                  }
                  handleChange={
                    field.setAdditionalEditAttributesCallback
                      ? (event) => {
                          let additionalAttributesToSet =
                            field.setAdditionalEditAttributesCallback();
                          additionalAttributesToSet[field.accessor] =
                            event.target.value;

                          setTempModelObj({
                            ...tempModelObj,
                            ...additionalAttributesToSet,
                          });
                        }
                      : handleChange
                  }
                  required={field.required}
                  label={field.label}
                  amount={
                    field.valueCallback
                      ? field.valueCallback(tempModelObj)
                      : tempModelObj[field.accessor] || 0.0
                  }
                />
              );
            } else if (
              field.widget === "ComboBox" ||
              field.widget === "LeaveTakenTypeComboBox"
            ) {
              const ComboBoxComponent =
                field.widget === "ComboBox" ? ComboBox : LeaveTakenTypeComboBox;

              // Return null if no field.args.comboDataCallback(tempModelObj) is defined or it is empty
              if (
                field.args.comboDataCallback === undefined ||
                field.args.comboDataCallback(tempModelObj) === undefined ||
                field.args.comboDataCallback(tempModelObj).length === 0
              ) {
                return null;
              }

              return (
                <ComboBoxComponent
                  className="mx-auto mb-3 width-100"
                  parentObj={tempModelObj}
                  infoText={field.infoText || ""}
                  key={field.accessor}
                  skipSort={field.args.skipSort}
                  sortByKey={field.args.sortByKey}
                  name={field.accessor}
                  sortCallback={field.args.sortCallback}
                  label={field.label}
                  disabled={
                    field.disabled !== undefined ? field.disabled : false
                  }
                  onChange={handleChange}
                  comboData={field.args.comboDataCallback(tempModelObj)}
                  selectedComboItem={
                    tempModelObj[field.accessor] &&
                    tempModelObj[field.accessor].selected_item
                      ? tempModelObj[field.accessor].selected_item
                      : tempModelObj[field.accessor]
                  }
                  setSelectedComboItem={(data) => {
                    let keyFromVal = adminContext.getKeyByValue(
                      field.args.comboDataCallback(tempModelObj),
                      data
                    );
                    let newModelObj;
                    if (field.args.idAliasCallback) {
                      newModelObj = {
                        ...tempModelObj,
                        [field.args.idAlias]:
                          field.args.idAliasCallback(keyFromVal),
                        [field.accessor]: keyFromVal,
                      };
                    } else {
                      newModelObj = {
                        ...tempModelObj,
                        [field.accessor]: keyFromVal,
                      };
                    }
                    setTempModelObj(newModelObj);
                    if (field.args.updatedCallback) {
                      field.args.updatedCallback(
                        newModelObj,
                        setTempModelObj,
                        keyFromVal
                      );
                    }
                  }}
                />
              );
            } else if (field.widget === "MultiplierEdit") {
              return (
                <MultiplierEdit
                  name={field.accessor}
                  key={field.accessor}
                  field={field}
                  strictLabel={field.args.strictLabel}
                  label={field.label}
                  value={tempModelObj[field.accessor]}
                  setValue={(value) => {
                    setTempModelObj({
                      ...tempModelObj,
                      [field.accessor]: value,
                    });
                  }}
                />
              );
            } else if (field.widget === "PercentageEdit") {
              return (
                <PercentageEdit
                  key={field.accessor}
                  label={field.label}
                  getPercentage={() => getPercentage(field.accessor)}
                  setPercentage={(percentage) =>
                    setPercentage(field.accessor, percentage)
                  }
                  disabled={
                    field.enabledCallback
                      ? !field.enabledCallback(tempModelObj)
                      : false
                  }
                />
              );
            } else if (field.widget === "BooleanLabel") {
              return (
                <div key={field.accessor} className="form-group">
                  {field.label}{" "}
                  <BooleanLabel value={tempModelObj[field.accessor]} />
                </div>
              );
            } else if (
              field.widget === "Switch" ||
              field.widget === "CheckBox"
            ) {
              return (
                <div key={field.accessor} className="form-group mb-1">
                  <FormControlLabel
                    control={
                      <CheckBox
                        color="primary"
                        className="mx-2"
                        name={field.accessor}
                        checked={tempModelObj[field.accessor] || false}
                        onChange={
                          field.args !== undefined &&
                          field.args.additionalChangeCallback !== undefined
                            ? (e) => {
                                field.args.additionalChangeCallback(e);
                                handleChange(e);
                              }
                            : handleChange
                        }
                      />
                    }
                    label={" " + field.label}
                  />
                </div>
              );
            }

            return (
              <CustomAvField
                key={field.accessor}
                name={field.accessor}
                label={field.label}
                type={field.widget}
                validate={field.validate}
                styleCallback={() => {
                  if (field.args && field.args.styleCallback) {
                    return field.args.styleCallback(tempModelObj);
                  }
                  return null;
                }}
                onChange={handleChange}
                value={tempModelObj[field.accessor]}
                infoText={field.infoText || ""}
              />
            );
          })}
          {props.buttonsUpTop === undefined || props.buttonsUpTop === false ? (
            <>
              <hr />
              {props.saveChanges ? (
                <Button
                  className="btn-lg my-2 semi-active-button"
                  color="primary"
                  type="submit"
                  disabled={errorText.length > 0}
                >
                  {props.saveButtonText ? props.saveButtonText : "Save Changes"}
                </Button>
              ) : null}
              {props.cancelCallback ? (
                <Button
                  className="btn-lg my-2 semi-active-button"
                  color="secondary"
                  onClick={() => props.cancelCallback()}
                >
                  Cancel
                </Button>
              ) : null}
            </>
          ) : null}
        </AvForm>
      </CardBody>
    </Card>
  );
};
export default EditForm;
