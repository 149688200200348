import { Row, Container, Alert, Col, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import EmployeesSelect from "../../components/Widgets/EmployeesSelect";
import AdminContext from "../../AdminContext";
import ActionsButtonGrid from "./ActionsButtonGrid";
import routes from "../../variables/routes";
import Tooltip from "../../components/Widgets/Tooltip";
import QuestionModal from "../../components/Widgets/QuestionModal";

const EmployeesNav = (props) => {
  const adminContext = useContext(AdminContext);
  const [navRoutes, setNavRoutes] = useState([]);

  const excludedPaths = ["/employees/leave/:paramLeaveType"];

  const initNavRoutes = () => {
    let detailsRoutes = [];

    routes.map((prop) => {
      if (prop.layout === "/admin") {
        if (prop.options.length > 0) {
          prop.options.map((innerProp) => {
            const fullPath = `/admin${innerProp.path}`;
            // Exclude the specific paths
            if (
              innerProp.path.includes(`/employees/${props.routesSubPath}/`) &&
              !excludedPaths.some((excludedPath) =>
                innerProp.path.includes(excludedPath)
              )
            ) {
              detailsRoutes.push({
                path: fullPath,
                name: innerProp.name,
                label: innerProp.label,
                short_description: innerProp.short_description,
                icon:
                  innerProp.buttonIcon !== undefined
                    ? innerProp.buttonIcon
                    : innerProp.icon !== undefined
                    ? innerProp.icon.replace("text-blue", "")
                    : "",
              });
            }
          });
        }
      }
    });

    setNavRoutes(detailsRoutes);
  };

  useEffect(() => {
    initNavRoutes();
    adminContext.initEmployees();
  }, []);

  const navButtonContent = (route) => {
    return (
      <Container>
        <Row>
          <Col className="align-items-center text-center mx-auto">
            <i className={route.icon !== undefined ? route.icon : ""} />
          </Col>
        </Row>
        <Row>
          <Col>{route.label !== undefined ? route.label : route.name} </Col>
        </Row>
      </Container>
    );
  };
  return (
    <Container className="mt-4" fluid>
      {adminContext.company.employees &&
      adminContext.company.employees.length > 0 ? (
        <Row className="mx-2">
          <Col xl="2" className="text-center m-20-on-sm  emp-nav-buttons">
            <Row >
              <ActionsButtonGrid />
            </Row>
            <Row>
              <EmployeesSelect />
            </Row>
            <hr />
            {navRoutes.length > 0 &&
              // Loop through navRoutes and render a row/col/button for each nav item
              navRoutes.map((route) => {
                return (
                  <Row key={route.label || route.name}>
                    <Col>
                      <Button
                        color="secondary"
                        className={
                          "width-100 btn btn-lg my-2  text-break " +
                          (window.location.href.includes(route.path)
                            ? " active-button"
                            : " ")
                        }
                        onClick={() => {
                          adminContext.history.push(route.path);
                        }}
                      >
                        {route.short_description !== undefined ? (
                          <Tooltip
                            title={route.short_description}
                            innerContent={<div> {navButtonContent(route)}</div>}
                          ></Tooltip>
                        ) : (
                          <div> {navButtonContent(route)}</div>
                        )}
                      </Button>
                    </Col>
                  </Row>
                );
              })}
          </Col>
          {adminContext.currentEmployee && adminContext.currentEmployee.id ? (
            <Col lg="10">
              <h1 className="days-one text-center mx-2 mt-2">
                <i className="fa-regular fa-id-card"></i>{" "}
                {adminContext.currentEmployee
                  ? adminContext.currentEmployee.first_name +
                    " " +
                    adminContext.currentEmployee.middle_name +
                    " " +
                    adminContext.currentEmployee.last_name
                  : ""}{" "}
                {adminContext.currentEmployee.locked ? (
                  <i className="fas fa-lock"></i>
                ) : (
                  ""
                )}
              </h1>
              {adminContext.currentEmployee &&
              adminContext.currentEmployee.termination_date &&
              adminContext.currentEmployee.is_terminated ? (
                <p className="text-center">
                  <span className="text-danger">
                    {" "}
                    (Terminated {adminContext.currentEmployee.termination_date})
                  </span>
                </p>
              ) : adminContext.currentEmployee.locked ? (
                <>
                  <p className="text-center">
                    <span>
                      This employee is <strong>locked</strong> and in read-only
                      mode. Please{" "}
                      <a href="https://www.lightningpayroll.com.au/faq?selected=4">
                        upgrade your subscription
                      </a>{" "}
                      (or terminate another employee) to unlock this employee.
                      If the entire company is locked. Click{" "}
                      <a
                        href="https://www.lightningpayroll.com.au/faq?selected=318"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      to learn more.
                    </span>
                  </p>
                  <p className="text-center text-danger">
                    Currently using {adminContext.numberActiveEmployees} out of{" "}
                    {adminContext.maxAllowedEmployees} total employees.
                  </p>
                </>
              ) : (
                ""
              )}
              {adminContext.currentEmployee &&
              props.subComponent !== undefined ? (
                <div key={props.subComponent}>
                  <fieldset
                    disabled={
                      adminContext.currentEmployee.locked ||
                      !adminContext.editAccess("employees")
                    }
                  >
                    <props.subComponent name={props.name} />
                  </fieldset>
                </div>
              ) : (
                <Alert color="warning">No employee selected</Alert>
              )}
            </Col>
          ) : (
            <Col lg="10">
              <h1 className="text-center days-one">No employee selected</h1>
            </Col>
          )}
        </Row>
      ) : (
        <div className="full-height"></div>
      )}
      <QuestionModal
        isOpen={
          !adminContext.nonDeletedEmployees() ||
          adminContext.nonDeletedEmployees().length === 0
        }
        title=""
        content={
          <p>
            No employees exist yet in this company. Would you like to add one
            now?
          </p>
        }
        onConfirm={() => {
          adminContext.history.push("/admin/employees/new_employee");
        }}
        onDeny={() => {
          adminContext.history.push("/admin/entities/select");
        }}
      />
    </Container>
  );
};
export default EmployeesNav;
