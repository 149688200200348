import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";
import { styled } from "@mui/material/styles";

const StyledMuiAlert = styled(MuiAlert)(({ theme }) => ({
  fontSize: "1.25rem", // Increase font size
  padding: theme.spacing(3), // Increase padding
  minWidth: "300px", // Set a minimum width
}));

const SnackbarAlert = forwardRef(function Alert(props, ref) {
  return <StyledMuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default SnackbarAlert;
