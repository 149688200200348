// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import validateABN from "../../validators/validateABN";
import validateEmail from "../../validators/validateEmail";

// core components
import AdminContext from "../../AdminContext";
import { useState } from "react";
import { useEffect, useContext } from "react";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import ComboBox from "../../components/Widgets/ComboBox";
import BackButton from "../../components/Widgets/BackButton";
import LoadingSpinner from "components/Widgets/LoadingSpinner";

const CompanyDetails = () => {
  // Cookies are required for authentication
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const adminContext = useContext(AdminContext);

  // Update the form on data change
  const handleChange = (event) => {
    adminContext.setCompany({
      ...adminContext.company,
      [event.target.name]: event.target.value,
    });
  };

  const submitStpCompanyDeclaration = () => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/submit-stp-declaration`,
      {},
      (response) => {
        adminContext.setAlertMessage("STP Declaration submitted successfully");
      }
    );
  };

  // Update the API on submit
  const saveChanges = (e) => {
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id || 0}?route=attrs_only`,
      { ...adminContext.company, id: adminContext.company.id || 0 },
      (response) => {
        if (response.status === 200) {
          // Set the selected company to the updated, latest company
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
          if (response.data.company) {
            adminContext.setCompany(response.data.company);
          }

          setTimeout(() => {
            if (
              response.data.new_company ||
              ((!adminContext.nonDeletedEmployees() ||
                adminContext.nonDeletedEmployees().length === 0) &&
                adminContext.company.id === 1)
            ) {
              updateCustomerDetails();
              adminContext.history.push("/admin/company/pay_dates");
            }
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const updateCustomerDetails = () => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id || 0}/update_customer_details`,
      {}
    );
  };

  const saveDisabled = () => {
    return !(
      adminContext.company.abn &&
      adminContext.company.name &&
      adminContext.company.tax_name &&
      adminContext.company.state &&
      adminContext.company.email &&
      validateABN(adminContext.company.abn) &&
      validateEmail(adminContext.company.email)
    );
  };

  if (!adminContext.company) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* Page content */}
      <fieldset disabled={!adminContext.editAccess("company")}>
        <Container className="mt-4" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <h1 className="days-one text-center">
                {adminContext.company.id
                  ? "Company Details"
                  : "Add New Company"}
              </h1>
              {!adminContext.company.id && (
                <BackButton
                  label="Cancel"
                  goBack={() => {
                    if (adminContext.companies.length) {
                      adminContext.setCompany(adminContext.companies[0]);
                    } else {
                      adminContext.setCompany({});
                    }
                    adminContext.history.push("/admin/entities/select");
                  }}
                />
              )}

              <SaveChangesMessageRow
                disabledCallback={saveDisabled}
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />
              {saveDisabled() && (
                <p className="text-center">
                  Please fill in all required fields and check the ABN and email
                  address are valid (* indicates a required field).
                </p>
              )}
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form key={adminContext.company.id}>
                    <h3 className="text-muted mb-4">Company information</h3>

                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-company-name"
                            >
                              Company Name *
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={adminContext.company.name}
                              id="input-company-name"
                              placeholder="Company name"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-company-name-tax"
                            >
                              Tax name *
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={adminContext.company.tax_name}
                              id="input-company-name-tax"
                              onChange={handleChange}
                              name="tax_name"
                              placeholder="Company name for tax purposes"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address *
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Enter email here"
                              value={adminContext.company.email}
                              name="email"
                              onChange={handleChange}
                              type="email"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-abn"
                            >
                              ABN *
                            </label>
                            <Input
                              className={
                                validateABN(adminContext.company.abn)
                                  ? "text-success"
                                  : "text-danger"
                              }
                              id="input-abn"
                              placeholder="Enter abn here"
                              value={adminContext.company.abn}
                              name="abn"
                              onChange={handleChange}
                              // Strip non numeric characters onblur
                              onBlur={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                handleChange(e);
                              }}
                              type="text"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-location-id"
                            >
                              Branch ID
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-location-id"
                              placeholder="Enter PAYG branch ID here"
                              value={adminContext.company.location_id}
                              name="location_id"
                              onChange={handleChange}
                              type="text"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-phone"
                              placeholder="Enter phone here"
                              value={adminContext.company.phone}
                              name="phone"
                              onChange={handleChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Address information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address1"
                            >
                              Address line 1
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={adminContext.company.address1}
                              onChange={handleChange}
                              name="address1"
                              id="input-address1"
                              placeholder="Address line 1"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address2"
                            >
                              Address line 2
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={adminContext.company.address2}
                              id="input-address2"
                              name="address2"
                              onChange={handleChange}
                              placeholder="Address line 2"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-city"
                              placeholder="Enter city here"
                              value={adminContext.company.city}
                              name="city"
                              onChange={handleChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <div>
                            <ComboBox
                              disabled={!adminContext.editAccess("company")}
                              label="State *"
                              stretch={true}
                              className="mx-3"
                              name="state"
                              onChange={handleChange}
                              comboData={adminContext.constants.STATES}
                              selectedComboItem={adminContext.company.state}
                              setSelectedComboItem={(data) =>
                                adminContext.setCompany({
                                  ...adminContext.company,
                                  state: data,
                                })
                              }
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-postcode"
                            >
                              Postcode
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postcode"
                              placeholder="Enter postcode here"
                              value={adminContext.company.postcode}
                              name="postcode"
                              onChange={handleChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact person information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              onChange={handleChange}
                              name="contact_first_name"
                              value={adminContext.company.contact_first_name}
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-middle-name"
                            >
                              Middle name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-middle-name"
                              value={adminContext.company.contact_middle_name}
                              name="contact_middle_name"
                              onChange={handleChange}
                              placeholder="Middle name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              onChange={handleChange}
                              name="contact_last_name"
                              value={adminContext.company.contact_last_name}
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                  {adminContext.company.id &&
                    adminContext.company.employees.length > 0 &&
                    !adminContext.company.declaration_indicator && (
                      <div class="my-3">
                        <hr />
                        <Row>
                          <Col>
                            <h3>Manual STP Declaration (API users only)</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              To send Single Touch Payroll for this company, you
                              will need to agree to the following declaration,
                              as required by the Australian Taxation Office.
                            </p>
                            <p>
                              <strong>
                                'I declare the information transmitted in these
                                STP payroll reports is true and correct and I am
                                authorised to make this declaration.'
                              </strong>
                            </p>
                            <p>
                              Please click 'Yes' to agree, thereby signing this
                              declaration.
                            </p>
                            <Button onClick={submitStpCompanyDeclaration}>
                              Yes
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </fieldset>
    </>
  );
};

export default CompanyDetails;
