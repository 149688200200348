import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useState, useContext } from "react";
import MoneyEdit from "../../../components/Widgets/MoneyEdit";
import moment from "moment";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import dayjs from "dayjs";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import FormControlLabel from "@mui/material/FormControlLabel";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import CheckBox from "@mui/material/Checkbox";
import { FormControl, Radio, RadioGroup } from "@mui/material";
import PercentageEdit from "../../../components/Widgets/PercentageEdit";
import DateEdit from "../../../components/Widgets/DateEdit";
import validateTFN from "../../../validators/validateTFN";

const TaxSettings = () => {
  const adminContext = useContext(AdminContext);
  const [upwardsVariationMode, setUpwardsVariationMode] = useState("amount"); // [rate, amount]
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const determineUpwardsVariationMode = (employee) => {
    if (employee.upwards_variation_rate > 0) {
      return "rate";
    }
    if (
      employee.upwards_variation_amount &&
      employee.upwards_variation_amount._decimal > 0
    ) {
      return "amount";
    }
    return "amount";
  };

  const handleSetCurrentEmployee = (newEmployeeData) => {
    const mode = determineUpwardsVariationMode(newEmployeeData);
    setUpwardsVariationMode(mode);
    adminContext.setCurrentEmployee(newEmployeeData);
  };

  const handleChange = (event) => {
    let newEmployeeData = { ...adminContext.currentEmployee };

    if (event.target.name === "upwards_variation_amount") {
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: event.target.value,
        upwards_variation_rate: 0,
      };
    } else if (event.target.name === "tax_file_number") {
      // Strip out any non numeric characters from the TFN
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: event.target.value.replace(/\D/g, ""),
      };
    } else if (event.target.name === "upwards_variation_rate") {
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: event.target.value,
        upwards_variation_amount: { _decimal: 0 },
      };
    } else if (event.target.name === "has_voluntary_rate") {
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: event.target.checked,
      };
    } else if (event.target.name === "has_automatic_tax") {
      newEmployeeData = {
        ...newEmployeeData,
        has_voluntary_rate: !event.target.checked,
      };
    } else if (event.target.type === "checkbox") {
      if (event.target.name === "is_foreign_resident") {
        newEmployeeData = {
          ...newEmployeeData,
          is_australian_resident: !event.target.checked,
        };
      } else {
        newEmployeeData = {
          ...newEmployeeData,
          [event.target.name]: event.target.checked,
        };
      }
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: newDate,
      };
    } else {
      newEmployeeData = {
        ...newEmployeeData,
        [event.target.name]: event.target.value,
      };
    }

    // Use the handleSetCurrentEmployee function to update the state which will update the upwards variation mode accordingly
    handleSetCurrentEmployee(newEmployeeData);
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const getPercentage = (accessor) => {
    return (
      Math.round(Number(adminContext.currentEmployee[accessor]) * 100 * 100) /
      100
    );
  };

  const setPercentage = (accessor, percentage) => {
    let roundedPercentage = Math.round(percentage * 100) / 100 / 100;

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      [accessor]: roundedPercentage,
    });
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm>
              <Row>
                <Col>
                  <h1 className="days-one">Tax Settings</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />

              <Row>
                <Col className="mx-1 my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <AvField
                      className={
                        validateTFN(
                          adminContext.currentEmployee.tax_file_number
                        )
                          ? "text-success"
                          : "text-danger"
                      }
                      key={"tax_file_number"}
                      name={"tax_file_number"}
                      label={"Tax File Number"}
                      type={"text"}
                      onChange={handleChange}
                      value={adminContext.currentEmployee.tax_file_number}
                    />
                  </div>
                </Col>
                <Col className="mx-1 my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <AvField
                      key={"tax_treatment_code"}
                      name={"tax_treatment_code"}
                      label={"Tax Treatment Code"}
                      disabled={true}
                      type={"text"}
                      value={adminContext.currentEmployee.tax_treatment_code}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <Row>
                    <Col className="mx-1">
                      <FormControlLabel
                        control={
                          <CheckBox
                            checked={
                              !adminContext.currentEmployee.has_voluntary_rate
                            }
                            onChange={handleChange}
                            name="has_automatic_tax"
                          />
                        }
                        label={<strong>System Generated Tax Rate?</strong>}
                      />
                    </Col>
                  </Row>
                  {!adminContext.currentEmployee.has_voluntary_rate ? (
                    <>
                      <Row>
                        <Col className="mx-1">
                          <FormControlLabel
                            key={
                              adminContext.currentEmployee.has_help_liability
                            }
                            control={
                              <CheckBox
                                checked={
                                  adminContext.currentEmployee
                                    .has_help_liability
                                }
                                onChange={handleChange}
                                name="has_help_liability"
                              />
                            }
                            label={"Deduct HELP"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-1">
                          <FormControlLabel
                            key={adminContext.currentEmployee.has_tsl_liability}
                            control={
                              <CheckBox
                                checked={
                                  adminContext.currentEmployee.has_tsl_liability
                                }
                                onChange={handleChange}
                                name="has_tsl_liability"
                              />
                            }
                            label={"Deduct TSL"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-1">
                          <FormControlLabel
                            key={
                              adminContext.currentEmployee.has_sfss_liability
                            }
                            control={
                              <CheckBox
                                checked={
                                  adminContext.currentEmployee
                                    .has_sfss_liability
                                }
                                onChange={handleChange}
                                name="has_sfss_liability"
                              />
                            }
                            label={"Deduct SFSS"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-1">
                          <FormControlLabel
                            control={
                              <CheckBox
                                checked={
                                  adminContext.currentEmployee
                                    .has_claimed_tax_free_threshold
                                }
                                onChange={handleChange}
                                name="has_claimed_tax_free_threshold"
                              />
                            }
                            label={"Tax Free Threshold Claimed"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-1">
                          <FormControlLabel
                            control={
                              <CheckBox
                                checked={
                                  !adminContext.currentEmployee
                                    .is_australian_resident
                                }
                                onChange={handleChange}
                                name="is_foreign_resident"
                              />
                            }
                            label={"Foreign Resident"}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </Col>
                {!adminContext.currentEmployee.has_voluntary_rate ? (
                  <Col className="mx-1">
                    <Row>
                      <Col>
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={
                                adminContext.currentEmployee
                                  .has_upwards_variation
                              }
                              onChange={handleChange}
                              name="has_upwards_variation"
                            />
                          }
                          label={"Upwards Variation"}
                        />
                      </Col>
                    </Row>
                    {adminContext.currentEmployee.has_upwards_variation && (
                      <Row>
                        <Col className="ml-4">
                          <FormControl>
                            <RadioGroup
                              name="upwards_variation_mode"
                              onChange={(event) => {
                                setUpwardsVariationMode(event.target.value);
                                // If setting to rate we should zero amount and vice versa
                                if (event.target.value === "rate") {
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    upwards_variation_amount: { _decimal: 0 },
                                  });
                                } else {
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    upwards_variation_rate: 0,
                                  });
                                }
                              }}
                            >
                              <FormControlLabel
                                className="px-3"
                                value="amount"
                                control={
                                  <Radio
                                    checked={upwardsVariationMode === "amount"}
                                  />
                                }
                                label="As a set dollar amount per pay"
                              />
                              <FormControlLabel
                                className="px-3"
                                value="rate"
                                control={
                                  <Radio
                                    checked={upwardsVariationMode === "rate"}
                                  />
                                }
                                label="As a percentage of tax"
                              />
                            </RadioGroup>
                          </FormControl>
                          {adminContext.currentEmployee.has_upwards_variation &&
                          upwardsVariationMode === "amount" ? (
                            <MoneyEdit
                              key={"upwards_variation_amount"}
                              name={"upwards_variation_amount"}
                              handleChange={handleChange}
                              label={"Upwards Variation Amount"}
                              amount={
                                adminContext.currentEmployee
                                  .upwards_variation_amount
                              }
                            />
                          ) : null}
                          {adminContext.currentEmployee.has_upwards_variation &&
                          upwardsVariationMode === "rate" ? (
                            <PercentageEdit
                              key={"upwards_variation_rate"}
                              label={"Upwards Variation %"}
                              getPercentage={() =>
                                getPercentage("upwards_variation_rate")
                              }
                              setPercentage={(percentage) =>
                                setPercentage(
                                  "upwards_variation_rate",
                                  percentage
                                )
                              }
                            />
                          ) : null}
                          <p>
                            Note: If percentage mode is chosen, the varied rate
                            cannot result in a lesser amount being withheld than
                            would otherwise apply under the PAYG withholding
                            tables.
                          </p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                ) : null}
              </Row>
              <Row className="mx-0">
                <Col>
                  <FormControlLabel
                    control={
                      <CheckBox
                        checked={
                          adminContext.currentEmployee.has_voluntary_rate
                        }
                        onChange={handleChange}
                        name="has_voluntary_rate"
                      />
                    }
                    label={
                      "Manual variation of PAYG withholding / Voluntary Agreement"
                    }
                  />
                  {adminContext.currentEmployee.has_voluntary_rate ? (
                    <>
                      <PercentageEdit
                        key={"voluntary_rate"}
                        label={"% of taxable pay"}
                        getPercentage={() => getPercentage("voluntary_rate")}
                        setPercentage={(percentage) =>
                          setPercentage("voluntary_rate", percentage)
                        }
                      />
                      {}
                      <AvField
                        key={"abn"}
                        name={"abn"}
                        label={
                          "Payee's ABN (If employed under a voluntary agreement)"
                        }
                        type={"text"}
                        value={adminContext.currentEmployee.abn}
                        onChange={handleChange}
                      />
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row className="mx-2">
                <Col>
                  <div key={"tfnd_signed_date"} className="my-4">
                    <DateEdit
                      label={"Date TFN declaration was signed"}
                      value={adminContext.currentEmployee.tfnd_signed_date}
                      onChange={(time) => {
                        if (!dayjs(time).isValid()) {
                          return;
                        }
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          tfnd_signed_date: time,
                        });
                      }}
                      type="date"
                      name="tfnd_signed_date"
                    />
                  </div>
                </Col>
                <p>
                  This TFN declaration date field represents the date the TFN
                  declaration was signed. You do not need to mail TFN
                  declarations to the ATO if sending Single Touch Payroll as
                  this information is included within the employee's submission.
                  Do hold onto TFN declarations for your records, however.
                </p>
              </Row>
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default TaxSettings;
