const validateABN = (value) => {
  if (!value) {
    return false;
  }

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  // Remove all non-digit characters (including spaces)
  const abn = value.replace(/\D/g, "");

  // Check length is exactly 11 digits
  if (abn.length !== 11) {
    return false;
  }

  // Apply ATO check method
  let sum = 0;

  for (let i = 0; i < 11; i++) {
    let digit = parseInt(abn[i], 10) - (i === 0 ? 1 : 0);
    sum += weights[i] * digit;
  }

  return sum % 89 === 0;
};

export default validateABN;
