import AdminContext from "../../AdminContext";
import { useContext } from "react";

const AdminRedirect = () => {
  const adminContext = useContext(AdminContext);

  adminContext.history.push("/admin/entities/select");

  return null;
};

export default AdminRedirect;
